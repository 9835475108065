import React, { useState, useEffect } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  ChakraProvider,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Select,
} from "@chakra-ui/react";
import axios from "axios";
import { useLanguage } from "./LanguageContext";

const content = {
  en: {
    titleBranchOffice: "Add Branch Office",
    selectCompany: "Select Company",
    name: "Name of branch office:",
    cancel: "Cancel",
    save: "Save",
    add: "Branch added",
    errors: {
      validation: "Please enter values for all fields.",
      add: "Error adding branch office. Please try again.",
      nameRequired: "Please enter the name of the branch office.",
      selectedCompany: "Please select a company.",
    },
    success: {
      add: "The branch office has been added successfully.",
    },
  },
  es: {
    titleBranchOffice: "Agregar Sucursal",
    selectCompany: "Seleccione una Empresa",
    name: "Nombre de la sucursal:",
    cancel: "Cancelar",
    save: "Guardar",
    add: "Sucursal agregada",
    errors: {
      validation: "Por favor, ingrese valores para todos los campos.",
      add: "Error al agregar la sucursal. Por favor, inténtelo de nuevo.",
      nameRequired: "Por favor, ingrese el nombre de la sucursal.",
      selectedCompany: "Por favor, seleccione una empresa.",
    },
    success: {
      add: "La sucursal se ha agregado correctamente.",
    },
  },
};

const ModalAddBranchOffice = ({ isOpen, onClose, fetchDataBranch }) => {
  const API_ENDPOINT_COMPANIES = "https://api-almacenamiento.in/companies";
  const API_ENDPOINT_BRANCH = "https://api-almacenamiento.in/branch-offices";
  const toast = useToast();
  const { language } = useLanguage();
  const [formData, setFormData] = useState({
    name: "",
  });
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchCompanies = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT_COMPANIES}/getAllCompanies`
      );
      setCompanies(response.data);
    } catch (error) {
      console.error("Error al buscar empresas:", error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchCompanies();
    }
  }, [isOpen]);

  const addBranchOffice = async () => {
    try {
      // Validación de campos requeridos
      if (!formData.name) {
        toast({
          title: "Error",
          description: content[language].errors.nameRequired,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (!selectedCompany) {
        toast({
          title: "Error",
          description: content[language].errors.selectedCompany,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      const response = await axios.post(
        `${API_ENDPOINT_BRANCH}/addBranchOffice`,
        {
          name: formData.name,
          companyId: selectedCompany,
        }
      );

      if (response.status === 201) {
        toast({
          title: content[language].add,
          description: content[language].success.add,
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        setFormData({
          name: "",
        });
        setSelectedCompany("");

        onClose && onClose();
      } else {
        toast({
          title: "Error",
          description: content[language].errors.add,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error al agregar sucursal:", error);
      console.error("Error de detalles:", error.response.data);
      toast({
        title: "Error",
        description: content[language].errors.add,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleClose = async () => {
    setFormData({
      name: "",
    });
    setSelectedCompany("");

    onClose && onClose();
    fetchDataBranch();
  };

  return (
    <ChakraProvider>
      <Modal isOpen={isOpen} onClose={() => handleClose()}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="lg" fontWeight="bold">
            {content[language].titleBranchOffice}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              borderWidth="1px"
              rounded="lg"
              shadow="1px 1px 3px rgba(0,0,0,0.3)"
              maxWidth={800}
              p={6}
              m="10px auto"
              as="form"
            >
              <FormControl isRequired>
                <FormLabel htmlFor="name">{content[language].name}</FormLabel>
                <Input
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl mt={4} isRequired>
                <FormLabel htmlFor="company">
                  {content[language].selectCompany}
                </FormLabel>
                <Select
                  id="company"
                  name="company"
                  placeholder={content[language].selectCompany}
                  value={selectedCompany}
                  onChange={(e) => setSelectedCompany(e.target.value)}
                >
                  {companies.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup w="100%">
              <Flex w="100%" justifyContent="flex-end">
                <Button
                  onClick={() => handleClose()}
                  colorScheme="gray"
                  variant="outline"
                  w="5rem"
                  mr="2%"
                >
                  {content[language].cancel}
                </Button>
                <Button
                  onClick={addBranchOffice}
                  colorScheme="blue"
                  variant="solid"
                >
                  {content[language].save}
                </Button>
              </Flex>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};

export default ModalAddBranchOffice;
