import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  ChakraProvider,
  FormControl,
  InputGroup,
  Input,
  Button,
  Flex,
  Tooltip,
  Box,
  Select,
  HStack,
  VStack,
  Text,
  Spacer,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import { FaDownload, FaEye } from "react-icons/fa";
import {
  BiPlus,
  BiTrash,
  BiPencil,
  BiChevronDown,
  BiChevronUp,
} from "react-icons/bi";
import axios from "axios";
import { useLanguage } from "../componets/LanguageContext";
import ModalAddProject from "./ModalAddProject";
import ModalEditProject from "../componets/ModalEditProject";
import { jwtDecode } from "jwt-decode";
import QRCode from "qrcode.react";
import { useNavigate } from "react-router-dom";
import QRCode2 from "qrcode";
import jsPDF from "jspdf";
import siesaLogo from "../images/logoSiesa.png";
import acetiLogo from "../images/aceti.png";
import ppcLogo from "../images/ppc.png";
import pda from "../images/pro.jpg";

const content = {
  en: {
    searchCategory: "Search category",
    name: "Name of category",
    trash: "Delete",
    edit: "Edit",
    addCategory: "Add category",
    action: "Action",
    nameProject: "Name of project",
    nameCategory: "Category",
    nameBranch: "Branch office",
    nameCompany: "Company",
    addProject: "Add project",
    searchProject: "Search project",
    more: "More information",
    select: "Select category",
    selectCompany: "Select company",
    download: "Download sticker",
    view: "View blueprints",
    cancel: "Cancel",
    delete: "Delete",
    user: "User: ",
    confirmDeleteTitle: "Confirm Delete",
    confirmDeleteMessage: "Are you sure you want to delete this project?",
  },
  es: {
    searchCategory: "Buscar categoría",
    name: "Nombre de la categoría",
    trash: "Eliminar",
    edit: "Editar",
    addCategory: "Agregar categoría",
    action: "Acción",
    nameProject: "Nombre del proyecto",
    nameCategory: "Categoría",
    nameBranch: "Sucursal",
    nameCompany: "Empresa",
    addProject: "Agregar proyecto",
    searchProject: "Buscar proyecto",
    more: "Más información",
    select: "Seleccionar categoría",
    selectCompany: "Seleccionar empresa",
    download: "Descargar sticker",
    view: "Ver planos",
    cancel: "Cancelar",
    delete: "Eliminar",
    user: "Usuario: ",
    confirmDeleteTitle: "Confirmar eliminación",
    confirmDeleteMessage:
      "¿Estás seguro de que quieres eliminar este proyecto?",
  },
};

const ProjectDashboard = () => {
  const API_ENDPOINT_BRANCH = "https://api-almacenamiento.in/branch-offices";
  const API_ENDPOINT_USER = "https://api-almacenamiento.in/users";
  const API_ENDPOINT_CATEGORY = "https://api-almacenamiento.in/categories";
  const API_ENDPOINT_PROJECT = "https://api-almacenamiento.in/projects";
  const API_ENDPOINT_COMPANIES = "https://api-almacenamiento.in/companies";

  const { language } = useLanguage();
  const [categories, setCategories] = useState([]);
  const [projects, setProjects] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [searchProject, setSearchProject] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showButtonProject, setShowButtonProject] = useState(true);
  const [deleteProjectName, setDeleteProjectName] = useState(null);
  const [deleteProjectId, setDeleteProjectId] = useState(null);
  const cancelRef = useRef();
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const navigate = useNavigate();
  const [editProjectId, setEditProjectId] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [userRole, setUserRole] = useState("");
  const [userBranch, setUserBranch] = useState("");
  const [userCompany, setUserCompany] = useState("");
  const [userId, setUserId] = useState("");

  const toggleAccordion = (id) => {
    setActiveAccordion(activeAccordion === id ? null : id);
  };

  const fetchData = () => {
    axios
      .get(`${API_ENDPOINT_PROJECT}/project-details`)
      .then((response) => {
        setProjects(response.data);
      })

      .catch((error) => {
        console.error("Error al obtener datos de los proyectos", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    axios
      .get(`${API_ENDPOINT_CATEGORY}/getAllCategories`)
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.error("Error al obtener datos de las categorias", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${API_ENDPOINT_COMPANIES}/getAllCompanies`)
      .then((response) => {
        setCompanies(response.data);
      })
      .catch((error) => {
        console.error("Error al obtener datos de las empresas", error);
      });
  }, []);

  const handleIconClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchData();
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      // Decodificar el token
      const decodedToken = jwtDecode(token);
      if (decodedToken.role !== 1) {
        setShowButtonProject(false);
        setUserId(decodedToken.id);
        setUserRole(decodedToken.role);
      } else {
        setUserId(decodedToken.id);
      }
    }
  }, []);

  const handleDeleteProject = (projectId, projectName) => {
    setDeleteProjectId(projectId);
    setDeleteProjectName(projectName);
    setIsDeleteConfirmationModalOpen(true);
  };

  const handleCancelDelete = () => {
    setDeleteProjectId(null);
    setIsDeleteConfirmationModalOpen(false);
  };

  const handleConfirmDelete = () => {
    axios
      .delete(`${API_ENDPOINT_PROJECT}/deleteProject/${deleteProjectId}`)
      .then((response) => {
        console.log("Projecto eliminado:", response.data);
        setDeleteProjectId(null);
        setIsDeleteConfirmationModalOpen(false);
        fetchData();
      })
      .catch((error) => {
        console.error("Error al eliminar projecto:", error);
      });
  };

  const handleEditProject = (projectId) => {
    setEditProjectId(projectId);
    setIsEditModalOpen(true);
  };

  const generatePDF = async (project) => {
    const pdf = new jsPDF({
      unit: "mm",
      format: [60, 45],
      orientation: "landscape",
    });

    const qrData = `${
      window.location.origin
    }/data/blueprints/${encodeURIComponent("project")}/${
      project.id
    }/${encodeURIComponent(project.name)}`;

    const qrOptions = {
      margin: 1,
    };
    const qrImage = await QRCode2.toDataURL(qrData, qrOptions);

    const qrWidth = 26;
    const qrHeight = qrWidth;
    pdf.addImage(qrImage, "JPEG", 2, 2, qrWidth, qrHeight);

    // Ajustar el tamaño de la letra
    pdf.setFontSize(7); // Aumentar el tamaño de la letra

    const pdfWidth = pdf.internal.pageSize.getWidth(); // Ancho de la página PDF
    const textWidth = 29; // Ancho del texto que quieres agregar

    // Calcular la posición X del texto
    const textX = pdfWidth - textWidth - 1;

    // Calcular la posición Y inicial del texto
    const textY = 5;
    const lineHeight = 3; // Espacio entre cada línea de texto

    // Calcular el máximo de caracteres a mostrar en el nombre
    const maxNameLength = 20; // Longitud máxima del nombre
    let truncatedName = project.name.substring(0, maxNameLength); // Obtener los primeros 15 caracteres

    // Agregar "..." si el nombre supera los 15 caracteres
    if (project.name.length > maxNameLength) {
      truncatedName += "...";
    }

    pdf.text(`Proyecto:`, textX, textY);

    pdf.setFont("helvetica", "bold");

    // Agregar el valor del proyecto debajo del texto "Nombre:"
    pdf.text(`${truncatedName}`, textX, textY + lineHeight);

    pdf.setFont("helvetica", "normal");

    // Calcular el máximo de caracteres a mostrar en la descripción
    const maxDescriptionLength = 20; // Longitud máxima de la descripción
    let truncatedDescription = project.description.substring(
      0,
      maxDescriptionLength
    );

    if (project.description.length > maxDescriptionLength) {
      truncatedDescription += "...";
    }

    // Agregar el texto "Descripción:" en la siguiente línea
    pdf.text(`Descripción:`, textX, textY + 2 * lineHeight);

    pdf.setFont("helvetica", "bold");

    // Agregar la descripción truncada al PDF
    pdf.text(`${truncatedDescription}`, textX, textY + 3 * lineHeight);

    pdf.setFont("helvetica", "normal");

    // Calcular el máximo de caracteres a mostrar en el nombre
    const maxSucursalLength = 20; // Longitud máxima del nombre
    let truncatedSucursal = project.BranchOffice.name.substring(
      0,
      maxSucursalLength
    ); // Obtener los primeros 15 caracteres

    // Agregar "..." si el nombre supera los 15 caracteres
    if (project.BranchOffice.name.length > maxSucursalLength) {
      truncatedSucursal += "...";
    }

    // Agregar el texto "Sucursal:" seguido del nombre de la sucursal del proyecto
    pdf.text(`Sucursal:`, textX, textY + 4 * lineHeight);

    pdf.setFont("helvetica", "bold");

    // Agregar el nombre de la sucursal en la siguiente línea
    pdf.text(`${truncatedSucursal}`, textX, textY + 5 * lineHeight);

    pdf.setFont("helvetica", "normal");

    // Calcular el máximo de caracteres a mostrar en el nombre
    const maxEmpresaLength = 15; // Longitud máxima del nombre
    let truncatedEmpresa = project.BranchOffice.Company.name.substring(
      0,
      maxEmpresaLength
    ); // Obtener los primeros 15 caracteres

    // Agregar "..." si el nombre supera los 15 caracteres
    if (project.BranchOffice.Company.name.length > maxEmpresaLength) {
      truncatedEmpresa += "...";
    }

    pdf.text(`Empresa:`, textX, textY + 6 * lineHeight);

    pdf.setFont("helvetica", "bold");

    pdf.text(`${truncatedEmpresa}`, textX, textY + 7 * lineHeight);

    pdf.setFont("helvetica", "normal");

    // logo de la empresa

    if (project.BranchOffice.Company.name === "ACETI OXIGENO") {
      const logoWidth1 = 23;
      const logoHeight1 = 13;
      const logoX1 = 31;
      const logoY1 = 29;
      pdf.addImage(acetiLogo, "JPEG", logoX1, logoY1, logoWidth1, logoHeight1);
    } else if (project.BranchOffice.Company.name === "PPC") {
      const logoWidth1 = 23;
      const logoHeight1 = 13;
      const logoX1 = 31;
      const logoY1 = 29;
      pdf.addImage(ppcLogo, "JPEG", logoX1, logoY1, logoWidth1, logoHeight1);
    } else if (project.BranchOffice.Company.name === "SIESA") {
      const logoWidth1 = 23;
      const logoHeight1 = 13;
      const logoX1 = 31;
      const logoY1 = 29;
      pdf.addImage(siesaLogo, "JPEG", logoX1, logoY1, logoWidth1, logoHeight1);
    } else if (project.BranchOffice.Company.name === "Productos del Aire") {
      const logoWidth1 = 23;
      const logoHeight1 = 13;
      const logoX1 = 31;
      const logoY1 = 29;
      pdf.addImage(pda, "JPEG", logoX1, logoY1, logoWidth1, logoHeight1);
    }

    /*  const logoWidth1 = 23;
    const logoHeight1 = 13;
    const logoX1 = 31;
    const logoY1 = 29;
    pdf.addImage(logoEmpresa, "JPEG", logoX1, logoY1, logoWidth1, logoHeight1); */

    // Agregar la segunda imagen al PDF
    const logoWidth2 = 26;
    const logoHeight2 = 15;
    const logoX2 = 2;
    const logoY2 = 29;
    pdf.addImage(siesaLogo, "JPEG", logoX2, logoY2, logoWidth2, logoHeight2);

    pdf.save(`${project.name}_project_stickerQR.pdf`);
  };

  const handleViewBlueprint = (id, name) => {
    if (!id || !name) {
      console.error(
        "No se puede ver el id del proyecto o nombre no definidos."
      );
      return;
    }

    const type = "project";
    const url = `/data/blueprints/${encodeURIComponent(
      type
    )}/${id}/${encodeURIComponent(name)}`;

    navigate(url);
  };

  useEffect(() => {
    const fetchDataBranch = async () => {
      try {
        const response = await axios.get(
          `${API_ENDPOINT_USER}/user-details/${userId}`
        );

        const branch = response.data.BranchOfficeId;
        setUserBranch(branch);
      } catch (error) {
        console.error("Error al obtener datos de usuario y su sucursal", error);
      }
    };

    fetchDataBranch();
  }, [userId, userBranch]);

  useEffect(() => {
    const fetchDataCompany = async () => {
      if (userBranch !== undefined) {
        try {
          const response = await axios.get(
            `${API_ENDPOINT_BRANCH}/branch-office-details/${userBranch}`
          );

          const company = response.data.CompanyId;
          setUserCompany(company);
        } catch (error) {
          console.error(
            "Error al obtener datos de la sucursal y su empresa",
            error
          );
        }
      }
    };

    fetchDataCompany();
  }, [userBranch, userCompany]);

  return (
    <ChakraProvider>
      <Box
        style={{
          maxHeight: "100%",
          overflowY: "scroll",
          padding: "1rem",
        }}
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems="center"
          pb={4}
          justify="space-between"
        >
          <Select
            placeholder={content[language].select}
            maxW={{ base: "100%", md: "250px" }}
            mb={{ base: 2, md: 0 }}
            mr={{ base: 0, md: 2 }}
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            {categories.map((category) => (
              <option key={category.id} value={category.name}>
                {category.name}
              </option>
            ))}
          </Select>
          {showButtonProject && (
            <>
              <Select
                placeholder={content[language].selectCompany}
                maxW={{ base: "100%", md: "250px" }}
                mb={{ base: 2, md: 0 }}
                mr={{ base: 0, md: 2 }}
                value={selectedCompany}
                onChange={(e) => setSelectedCompany(e.target.value)}
              >
                {companies.map((company) => (
                  <option key={company.id} value={company.name}>
                    {company.name}
                  </option>
                ))}
              </Select>
            </>
          )}

          <FormControl flex="1" mb={{ base: 2, md: 0 }} mr={{ base: 0, md: 2 }}>
            <InputGroup>
              <Input
                type="text"
                name="name"
                placeholder={content[language].searchProject}
                value={searchProject}
                onChange={(e) => setSearchProject(e.target.value)}
                maxW={{ base: "100%", md: "300px" }}
              />
            </InputGroup>
          </FormControl>
          {showButtonProject && (
            <Button
              bg={"gray.200"}
              color="black"
              _hover={{
                bg: "gray.300",
              }}
              borderColor="black"
              borderWidth="2px"
              leftIcon={<BiPlus />}
              mt={{ base: 2, md: 0 }}
              w={{ base: "95%", md: "auto" }}
              onClick={handleIconClick}
            >
              {content[language].addProject}
            </Button>
          )}
        </Flex>

        <TableContainer
          style={{
            boxShadow:
              "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>{content[language].nameProject}</Th>
                <Th>{content[language].nameCategory}</Th>
                <Th>{content[language].nameBranch}</Th>
                <Th>{content[language].nameCompany}</Th>
                <Th>{content[language].action}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {projects
                .filter((project) => {
                  const projectName = project.name.toLowerCase();
                  const searchByProject = projectName.includes(
                    searchProject.toLowerCase()
                  );
                  if (selectedCategory === "") {
                    return searchByProject;
                  } else {
                    const searchByCategory =
                      project.Category.name === selectedCategory;
                    return searchByProject && searchByCategory;
                  }
                })
                .filter((project) => {
                  const companyName =
                    project.BranchOffice.Company.name.toLowerCase();
                  const searchByCompany = companyName.includes(
                    selectedCompany.toLowerCase()
                  );
                  if (selectedCompany === "") {
                    return true;
                  } else {
                    return searchByCompany;
                  }
                })
                .filter((project) => {
                  if (parseInt(userRole) === 2 || parseInt(userRole) === 3) {
                    // "General Manager" y "Operations Manager"
                    return project.BranchOffice.Company.id === userCompany;
                  } else if (
                    parseInt(userRole) === 4 ||
                    parseInt(userRole) === 5
                  ) {
                    // "General Manager(branch)" y "Operations Manager(branch)"
                    return project.BranchOffice.id === userBranch;
                  } else {
                    return true;
                  }
                })
                .map((project) => (
                  <React.Fragment key={project.id}>
                    <Tr key={project.id}>
                      <Td>{project.name}</Td>
                      <Td>{project.Category.name}</Td>
                      <Td>{project.BranchOffice.name}</Td>
                      <Td>{project.BranchOffice.Company.name}</Td>

                      <Td>
                        {showButtonProject && (
                          <>
                            <Tooltip label={content[language].trash}>
                              <Button
                                colorScheme="red"
                                size="sm"
                                mr={2}
                                onClick={() =>
                                  handleDeleteProject(project.id, project.name)
                                }
                              >
                                <BiTrash />
                              </Button>
                            </Tooltip>
                            <Tooltip label={content[language].edit}>
                              <Button
                                colorScheme="blue"
                                size="sm"
                                mr={2}
                                onClick={() => handleEditProject(project.id)}
                              >
                                <BiPencil />
                              </Button>
                            </Tooltip>
                          </>
                        )}
                        <Tooltip label={content[language].more}>
                          <Button
                            onClick={() => toggleAccordion(project.id)}
                            colorScheme="gray"
                            size="sm"
                            mr={2}
                          >
                            {activeAccordion === project.id ? (
                              <BiChevronUp />
                            ) : (
                              <BiChevronDown />
                            )}
                          </Button>
                        </Tooltip>
                      </Td>
                    </Tr>
                    {activeAccordion === project.id && (
                      <Tr>
                        <Td colSpan={2}>
                          <HStack align="start" spacing={4} p={4}>
                            <Box>
                              <QRCode
                                value={JSON.stringify({
                                  id: project.id,
                                  nameOfProject: project.name,
                                  description: project.description,
                                  blueprint: project.blueprint,
                                  branchOffice: project.BranchOffice.name,
                                  company: project.BranchOffice.Company.name,
                                })}
                                size={100}
                              />
                            </Box>

                            <VStack align="start" spacing={4}>
                              <Text fontWeight="bold">
                                {project.name + " | "}
                                {project.description}
                              </Text>

                              {/* Botones */}
                              <HStack>
                                <Flex
                                  direction={{ base: "column", md: "row" }}
                                  align={{ base: "start", md: "center" }}
                                  spacing={2}
                                >
                                  {showButtonProject && (
                                    <>
                                      <Button
                                        size="sm"
                                        colorScheme="gray"
                                        leftIcon={<FaDownload />}
                                        onClick={() => generatePDF(project)}
                                        mr={{ base: 0, md: 2 }}
                                      >
                                        {content[language].download}
                                      </Button>
                                    </>
                                  )}
                                  <Button
                                    size="sm"
                                    colorScheme="gray"
                                    leftIcon={<FaEye />}
                                    mt={{ base: 2, md: 0 }}
                                    onClick={() =>
                                      handleViewBlueprint(
                                        project.id,
                                        project.name
                                      )
                                    }
                                  >
                                    {content[language].view}
                                  </Button>
                                </Flex>
                              </HStack>
                            </VStack>

                            <Spacer />
                          </HStack>
                        </Td>
                      </Tr>
                    )}
                  </React.Fragment>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>

      {deleteProjectId && (
        <AlertDialog
          isOpen={isDeleteConfirmationModalOpen}
          leastDestructiveRef={cancelRef}
          onClose={handleCancelDelete}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                {content[language].confirmDeleteTitle}{" "}
                <strong>{deleteProjectName}</strong>
              </AlertDialogHeader>

              <AlertDialogBody>
                {content[language].confirmDeleteMessage}
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={handleCancelDelete}>
                  {content[language].cancel}
                </Button>
                <Button colorScheme="red" onClick={handleConfirmDelete} ml={3}>
                  {content[language].delete}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}

      {editProjectId && (
        <ModalEditProject
          isOpen={isEditModalOpen}
          onClose={() => {
            setEditProjectId(null);
            setIsEditModalOpen(false);
            fetchData();
          }}
          projectId={editProjectId}
          fetchData={fetchData}
        />
      )}

      <ModalAddProject
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        fetchData={fetchData}
      />
    </ChakraProvider>
  );
};

export default ProjectDashboard;
