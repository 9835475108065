import React, { useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  ChakraProvider,
  Box,
  TableContainer,
  /*  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter, */
} from "@chakra-ui/react";
import { useLanguage } from "./LanguageContext";
import { FaDownload, FaEye } from "react-icons/fa";
import { useParams } from "react-router-dom";
import JSZip from "jszip";
import FileSaver from "file-saver";
import axios from "axios";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
/* import { useNavigate } from "react-router-dom"; */

const content = {
  en: {
    available: "No blueprints available",
    title: "Equipment blueprints",
    name: "Name of blueprint",
    actions: "Actions",
    download: "Download",
    number: "#",
    downloadAll: "Download All",
    view: "View",
    message:
      "Sorry, you don't have permission to view the blueprints of this branch.",
    titleBranch: "You don't have required permissions.",
  },
  es: {
    available: "No hay planos disponibles",
    title: "Planos de equipos",
    name: "Nombre del plano",
    actions: "Acciones",
    download: "Descargar",
    number: "#",
    downloadAll: "Descargar todo",
    view: "Ver",
    message:
      "Lo sentimos, no tienes permisos para ver los planos de esta sucursal.",
    titleBranch: "No tienes permiso requeridos.",
  },
};

const Blueprints = () => {
  const API_ENDPOINT_EQUIPMENT = "https://api-almacenamiento.in/equipment";
  const API_ENDPOINT_PROJECT = "https://api-almacenamiento.in/projects";
  const API_ENDPOINT_HOST = "https://api-almacenamiento.in";
 /*  const API_ENDPOINT_USER = "https://api-almacenamiento.in/users"; */
  const { id, name, type, userId } = useParams();
  const [files, setFiles] = useState({ blueprint: [] });
  const { language } = useLanguage();
  const [error, setError] = useState(null);
  /* const [showModal, setShowModal] = useState(false); 
  const navigate = useNavigate();
  const [projectOptions, setProjectOptions] = useState([]);
  const [equipmentOptions, setEquipmentOptions] = useState([]); */

  useEffect(() => {
    fetchBlueprint(id);
    // eslint-disable-next-line
  }, [userId]);

  /* const fetchBlueprintUser = async (id) => {
    try {
      const userResponse = await axios.get(
        `${API_ENDPOINT_USER}/user-details/${userId}`
      );
      const userData = userResponse.data;

      fetchBlueprint(
        userData.BranchOffice.id,
        userData.Role.id,
        userData.BranchOffice.Company.id
      );
    } catch (error) {
      console.error("Error al obtener datos de usuario:", error);
    }
  }; */

  const fetchBlueprint = async (id) => {
    try {
      /*  if (parseInt(roleId) === 1) {
        setShowModal(false);
      } else if (parseInt(roleId) === 2 || parseInt(roleId) === 3) {
        if (type === "project") {
          axios
            .get(`${API_ENDPOINT_PROJECT}/project-details-branch/${branchId}`)
            .then((response) => {
              setProjectOptions(response.data.BranchOffice.id);
            })

            .catch((error) => {
              console.error("Error al obtener datos de los proyectos", error);
            });

          let foundBranchId = false;

          for (const key in projectOptions) {
            if (projectOptions[key] === branchId) {
              foundBranchId = true;
              break;
            }
          }

          if (foundBranchId) {
            setShowModal(false);
          } else {
            setShowModal(true);
          }
        } else {
          axios
            .get(
              `${API_ENDPOINT_EQUIPMENT}/equipment-details-branch/${branchId}`
            )
            .then((response) => {
              setEquipmentOptions(response.data.Project.BranchOffice.id);
            })

            .catch((error) => {
              console.error("Error al obtener datos de los proyectos", error);
            });

          if (parseInt(branchId) === equipmentOptions) {
            setShowModal(false);
          } else {
            setShowModal(true);
          }
        }
      } else if (parseInt(roleId) === 4 || parseInt(roleId) === 5) {
        if (type === "project") {
          axios
            .get(`${API_ENDPOINT_PROJECT}/project-details-branch/${branchId}`)
            .then((response) => {
              setProjectOptions(response.data.BranchOffice.id);
            })

            .catch((error) => {
              console.error("Error al obtener datos de los proyectos", error);
            });

          if (parseInt(branchId) === projectOptions) {
            setShowModal(false);
          } else {
            setShowModal(true);
          }
        } else {
          axios
            .get(
              `${API_ENDPOINT_EQUIPMENT}/equipment-details-branch/${branchId}`
            )
            .then((response) => {
              setEquipmentOptions(response.data.Project.BranchOffice.id);
            })

            .catch((error) => {
              console.error("Error al obtener datos de los proyectos", error);
            });

          let foundBranchId = false;

          for (const key in equipmentOptions) {
            if (equipmentOptions[key] === branchId) {
              foundBranchId = true;
              break;
            }
          }

          if (foundBranchId) {
            setShowModal(false);
          } else {
            setShowModal(true);
          }
        }
      }
 */
      let response;
      if (type === "project") {
        response = await axios.get(`${API_ENDPOINT_PROJECT}/files/${id}`);
      } else {
        response = await axios.get(`${API_ENDPOINT_EQUIPMENT}/files/${id}`);
      }

      const { blueprint } = response.data;
      setFiles({ blueprint: JSON.parse(blueprint) });
    } catch (error) {
      console.error("Error al recuperar archivos:", error);
    }
  };

  /* const handleCloseModal = () => {
    setShowModal(false);
    localStorage.removeItem("token");
    navigate("/");
  }; */

  const downloadAll = () => {
    const zip = new JSZip();
    const promises = files.blueprint.map(async (file) => {
      try {
        const response = await fetch(`${API_ENDPOINT_HOST}/archivos/${file}`);
        const blob = await response.blob();
        zip.file(file, blob);
      } catch (error) {
        console.error(`Error al descargar ${file}:`, error);
      }
    });

    Promise.all(promises)
      .then(() => zip.generateAsync({ type: "blob" }))
      .then((content) => {
        FileSaver.saveAs(content, `${name}.zip`);
      })
      .catch((error) => {
        console.error("Error al generar el archivo zip:", error);
      });
  };

  const handleDownload = async (file) => {
    try {
      const response = await fetch(`${API_ENDPOINT_HOST}/archivos/${file}`);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");

      const fileName = typeof file === "object" ? file.name : file;

      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error(`Error al descargar ${file}:`, error);
      setError(`Error al descargar ${file}: ${error.message}`);
    }
  };

  return (
    <ChakraProvider>
      <Box
        style={{
          maxHeight: "calc(100vh - 260px)",
          overflowY: "auto",
          padding: "1rem",
        }}
      >
        <Button
          colorScheme="gray"
          onClick={downloadAll}
          mb={4}
          ml={4}
          size="sm"
          leftIcon={<FaDownload />}
        >
          {content[language].downloadAll}
        </Button>
        <TableContainer
          style={{
            boxShadow:
              "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>{content[language].number}</Th>
                <Th>{content[language].name}</Th>
                <Th>{content[language].actions}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {files.blueprint.length > 0 ? (
                files.blueprint.map((file, index) => (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    <Td>{file}</Td>
                    <Td>
                      <Button
                        size="xs"
                        colorScheme="gray"
                        leftIcon={<FaEye />}
                        onClick={() =>
                          window.open(
                            `${API_ENDPOINT_HOST}/archivos/${file}`,
                            "_blank"
                          )
                        }
                      >
                        {content[language].view}
                      </Button>
                      <>
                        {error && <p>{error}</p>}
                        <Button
                          ml={2}
                          size="xs"
                          colorScheme="gray"
                          leftIcon={<FaDownload />}
                          onClick={() => handleDownload(file)}
                        >
                          {content[language].download}
                        </Button>
                      </>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={3}>{content[language].available}</Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>

        {/* Modal */}
        {/*  <Modal isOpen={showModal} onClose={handleCloseModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{content[language].titleBranch}</ModalHeader>
            <ModalBody>{content[language].message}</ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" onClick={handleCloseModal}>
                Salir
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal> */}
      </Box>
    </ChakraProvider>
  );
};

export default Blueprints;
