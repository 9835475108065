import React, { useState, useEffect } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  ChakraProvider,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import { useLanguage } from "./LanguageContext";

const content = {
  en: {
    titleCompany: "Edit Company",
    name: "Name of company:",
    uploadLogo: "Upload company logo:",
    cancel: "Cancel",
    save: "Save",

    titleEdit: "Edited company",
    errors: {
      validation: "Please select values for all dropdowns.",
      edit: "Error editing company. Please try again.",
      nameRequired: "Please provide a name for the company.",
      logoRequired: "Please upload a logo for the company.",
      image: "Only image type files are allowed.",
    },
    success: {
      edit: "The company has been edited successfully.",
    },
    remove: "Remove",
  },
  es: {
    titleCompany: "Editar Empresa",
    name: "Nombre de la empresa:",
    uploadLogo: "Subir logo de la empresa:",
    cancel: "Cancelar",
    save: "Guardar",
    titleEdit: "Empresa editada",
    errors: {
      validation: "Por favor, seleccione valores para todas las opciones.",
      edit: "Error al editar la empresa. Por favor, inténtelo de nuevo.",
      nameRequired: "Por favor, proporcione un nombre para la empresa.",
      logoRequired: "Por favor, cargue un logo para la empresa.",
      image: "Sólo se permiten archivos tipo imagen.",
    },
    success: {
      edit: "La empresa se ha editado correctamente.",
    },
    remove: "Eliminar",
  },
};

const ModalEditCompany = ({
  isOpen,
  onClose,
  companyId,
  fetchData,
  onEditSuccess,
  onEditSuccessCompany,
}) => {
  const API_ENDPOINT_COMPANIES = "https://api-almacenamiento.in/companies";
  const toast = useToast();
  const { language } = useLanguage();

  const [formData, setFormData] = useState({
    name: "",
    logo: null,
  });
  const [logoError, setLogoError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        if (companyId) {
          const response = await axios.get(
            `${API_ENDPOINT_COMPANIES}/company-details/${companyId}`
          );

          setFormData((prevData) => ({
            ...prevData,
            name: response.data.name || "",
          }));
        }
      } catch (error) {
        console.error("Error al obtener los datos de la empresa:", error);
      }
    };

    fetchCompanyDetails();
  }, [isOpen, companyId]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "logo" && (!files || files.length === 0)) {
      console.error("Los archivos son nulos o no están definidos.");
      return;
    }

    if (name === "logo") {
      const isValidLogoType = files[0].type.startsWith("image/");

      if (!isValidLogoType) {
        setLogoError(content[language].errors.image);
        return;
      }

      setLogoError("");
    }

    if (name === "logo" && files && files.length > 0) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const editCompany = async () => {
    try {
      if (!formData.name) {
        toast({
          title: "Error",
          description: content[language].errors.nameRequired,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (!formData.logo) {
        toast({
          title: "Error",
          description: content[language].errors.logoRequired,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      setLoading(true);

      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name);

      if (formData.logo) {
        formDataToSend.append("logo", formData.logo);
      }

      const response = await axios.put(
        `${API_ENDPOINT_COMPANIES}/updateCompany/${companyId}`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        toast({
          title: content[language].titleEdit,
          description: content[language].success.edit,
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        setFormData({
          name: "",
          logo: null,
        });
        onClose && onClose();
        onEditSuccess && onEditSuccess();
        onEditSuccessCompany && onEditSuccessCompany();
      } else {
        toast({
          title: "Error",
          description: content[language].errors.edit,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error al editar empresa:", error);
      toast({
        title: "Error",
        description: content[language].errors.edit,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = async () => {
    setFormData({
      name: "",
      logo: null,
    });

    setLogoError("");

    onClose && onClose();
    fetchData();
  };

  return (
    <ChakraProvider>
      <Modal isOpen={isOpen} onClose={() => handleClose()}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="lg" fontWeight="bold">
            {content[language].titleCompany}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              borderWidth="1px"
              rounded="lg"
              shadow="1px 1px 3px rgba(0,0,0,0.3)"
              maxWidth={800}
              p={6}
              m="10px auto"
              as="form"
            >
              <FormControl isRequired>
                <FormLabel htmlFor="name">{content[language].name}</FormLabel>
                <Input
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel htmlFor="logo">
                  {content[language].uploadLogo}
                </FormLabel>
                <Input
                  type="file"
                  id="logo"
                  name="logo"
                  onChange={handleChange}
                  accept="image/*"
                />
                {logoError && (
                  <Text mt={2} color="red.700">
                    {logoError}
                  </Text>
                )}
              </FormControl>
            </Box>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup w="100%">
              <Flex w="100%" justifyContent="flex-end">
                <Button
                  onClick={() => handleClose()}
                  colorScheme="gray"
                  variant="outline"
                  w="5rem"
                  mr="2%"
                >
                  {content[language].cancel}
                </Button>
                <Button
                  onClick={editCompany}
                  colorScheme="blue"
                  variant="solid"
                  isLoading={loading}
                >
                  {content[language].save}
                </Button>
              </Flex>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};

export default ModalEditCompany;
