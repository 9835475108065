import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import Navbar from "../componets/Navbar";
import Footer from "../componets/Footer";
import Information from "../componets/Information";
import { LanguageProvider } from "../componets/LanguageContext";

function Index() {
  return (
    <ChakraProvider>
      <LanguageProvider>
        <Navbar />
        <Information />
        <Footer />
      </LanguageProvider>
    </ChakraProvider>
  );
}

export default Index;
