import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  ChakraProvider,
  FormControl,
  InputGroup,
  Input,
  Button,
  Flex,
  Tooltip,
  Box,
  Divider,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Select,
  Text,
  Wrap,
  WrapItem,
  useToast,
} from "@chakra-ui/react";
import { BiPlus, BiTrash, BiPencil } from "react-icons/bi";
import axios from "axios";
import { useLanguage } from "../componets/LanguageContext";
import ModalAddCompany from "./ModalAddCompany";
import ModalEditCompany from "./ModalEditCompany";
import ModalAddBranch from "./ModalAddBranch";
import ModalEditBranch from "./ModalEditBranch";
import { jwtDecode } from "jwt-decode";
import { InfoIcon } from "@chakra-ui/icons";

const content = {
  en: {
    search: "Search company",
    logo: "Logo",
    name: "Name of company",
    trash: "Delete",
    edit: "Editar",
    add: "Add company",
    action: "Action",
    branchoffice: "Name of branch office",
    searchbranchoffice: "Search branch",
    addBranch: "Register branch",
    select: "Select company",
    confirmDeleteTitle: "Confirm Delete",
    confirmDeleteMessage: "Are you sure you want to delete this company?",
    confirmDeleteMessageBranch:
      "Are you sure you want to delete this branch office?",
    cancel: "Cancel",
    delete: "Delete",
    adm: "Administrator",
    gm: "General Manager",
    om: "Operations Manager",
    gmb: "General Manager (Branch)",
    tb: "Technician (branch)",
  },
  es: {
    search: "Buscar empresa",
    logo: "Logo",
    name: "Nombre de la empresa",
    trash: "Eliminar",
    edit: "Editar",
    add: "Agregar empresa",
    action: "Acción",
    branchoffice: "Nombre de la sucursal",
    searchbranchoffice: "Buscar sucursal",
    addBranch: "Registrar sucursal",
    select: "Seleccionar empresa",
    confirmDeleteTitle: "Confirmar Eliminación",
    confirmDeleteMessage: "¿Seguro que deseas eliminar esta empresa?",
    confirmDeleteMessageBranch: "¿Seguro que deseas eliminar esta sucursal?",
    cancel: "Cancelar",
    delete: "Eliminar",
    adm: "Administrador",
    gm: "Gerente General",
    om: "Gerente de Operaciones",
    gmb: "Gerente General (Sucursal)",
    tb: "Técnico (Sucursal)",
  },
};

const CompanyDashboard = () => {
  const API_ENDPOINT_COMPANIES = "https://api-almacenamiento.in/companies";
  const API_ENDPOINT_BRANCH = "https://api-almacenamiento.in/branch-offices";
  const API_ENDPOINT_USER = "https://api-almacenamiento.in/users";
  const API_ENDPOINT_PROJECT = "https://api-almacenamiento.in/projects";
  /* const API_ENDPOINT_EQUIPMENT = "https://api-almacenamiento.in/equipment"; */

  const toast = useToast();
  const statuses = ["info"];

  const { language } = useLanguage();
  const [companies, setCompanies] = useState([]);
  const [branchs, setBranchs] = useState([]);
  const cancelRef = useRef();
  const [searchCompanies, setSearchCompanies] = useState("");
  const [searchBranch, setSearchBranch] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenBranch, setIsModalOpenBranch] = useState(false);
  const [deleteCompanyId, setDeleteCompanyId] = useState(null);
  const [deleteCompanyName, setDeleteCompanyName] = useState(null);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const [editCompanyId, setEditCompanyId] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [logoTimestamp, setLogoTimestamp] = useState(Date.now());
  const [deleteBranchId, setDeleteBranchId] = useState(null);
  const [deleteBranchName, setDeleteBranchName] = useState(null);
  const [
    isDeleteConfirmationModalOpenBranch,
    setIsDeleteConfirmationModalOpenBranch,
  ] = useState(false);

  const [editBranchId, setEditBranchId] = useState(null);
  const [isEditModalOpenBranch, setIsEditModalOpenBranch] = useState(false);
  const [showButtonCompany, setShowButtonCompany] = useState(true);
  const [showButtonBranch, setShowButtonBranch] = useState(true);
  const [userRole, setUserRole] = useState("");
  const [userCompany, setUserCompany] = useState("");
  const [userBranch, setUserBranch] = useState("");
  const [userId, setUserId] = useState("");
  const [userEmail, setUserEmail] = useState("");
  /* const [user, setUser] = useState(""); */

  const fetchData = () => {
    axios
      .get(`${API_ENDPOINT_COMPANIES}/getAllCompanies`)
      .then((response) => {
        setCompanies(response.data);
      })
      .catch((error) => {
        console.error("Error al obtener datos de la empresa", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchDataBranch = () => {
    axios
      .get(`${API_ENDPOINT_BRANCH}/branch-office-details`)
      .then((response) => {
        setBranchs(response.data);
      })
      .catch((error) => {
        console.error("Error al obtener datos de las sucursales", error);
      });
  };

  useEffect(() => {
    fetchDataBranch();
  }, []);

  const handleIconClick = () => {
    setIsModalOpen(true);
  };

  const handleIconClickBranch = () => {
    setIsModalOpenBranch(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchData();
    fetchDataBranch();
  };

  const handleCloseModalBranch = () => {
    setIsModalOpenBranch(false);
    fetchDataBranch();
  };

  const handleDeleteCompany = (companyId, companyName) => {
    setDeleteCompanyId(companyId);
    setDeleteCompanyName(companyName);
    setIsDeleteConfirmationModalOpen(true);
  };

  const handleDeleteBranch = (branchId, branchName) => {
    setDeleteBranchId(branchId);
    setDeleteBranchName(branchName);
    setIsDeleteConfirmationModalOpenBranch(true);
  };

  const handleCancelDelete = () => {
    setDeleteCompanyId(null);
    setIsDeleteConfirmationModalOpen(false);
  };

  const handleCancelDeleteBranch = () => {
    setDeleteBranchId(null);
    setIsDeleteConfirmationModalOpenBranch(false);
  };

  const handleConfirmDelete = () => {
    axios
      .delete(`${API_ENDPOINT_COMPANIES}/deleteCompany/${deleteCompanyId}`)
      .then((response) => {
        setDeleteCompanyId(null);
        setDeleteCompanyName(null);
        setIsDeleteConfirmationModalOpen(false);
        fetchData();
        fetchDataBranch();
      })
      .catch((error) => {
        console.error("Error al eliminar empresa:", error);
      });
  };

  const handleConfirmDeleteBranch = () => {
    axios
      .delete(`${API_ENDPOINT_BRANCH}/deleteBranchOffice/${deleteBranchId}`)
      .then((response) => {
        setDeleteBranchId(null);
        setDeleteBranchName(null);
        setIsDeleteConfirmationModalOpenBranch(false);
        fetchDataBranch();
      })
      .catch((error) => {
        console.error("Error al eliminar la sucursal:", error);
      });

    axios
      .delete(`${API_ENDPOINT_PROJECT}/deleteProjectByBranch/${deleteBranchId}`)
      .then((response) => {})
      .catch((error) => {
        console.error("Error al eliminar projecto:", error);
      });

    /*   axios
      .delete(
        `${API_ENDPOINT_EQUIPMENT}/deleteEquipmentByProject/${deleteBranchId}`
      )
      .then((response) => {})
      .catch((error) => {
        console.error("Error al eliminar equipo:", error);
      }); */
  };

  const handleEditCompany = (companyId) => {
    setEditCompanyId(companyId);
    setIsEditModalOpen(true);
  };

  const handleEditBranch = (branchId) => {
    setEditBranchId(branchId);
    setIsEditModalOpenBranch(true);
  };

  useEffect(() => {
    axios
      .get(`${API_ENDPOINT_BRANCH}/branch-office-details`)
      .then((response) => {
        setBranchs(response.data);
      })
      .catch((error) => {
        console.error("Error al obtener datos de las sucursales", error);
      });
  }, []);

  const handleEditCompanySuccess = () => {
    fetchDataBranch();
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      // Decodificar el token
      const decodedToken = jwtDecode(token);
      if (decodedToken.role !== 1) {
        setShowButtonCompany(false);
        setShowButtonBranch(false);

        setUserId(decodedToken.id);
        setUserRole(decodedToken.role);
        setUserEmail(decodedToken.email);
      } else {
        setUserEmail(decodedToken.email);
        setUserId(decodedToken.id);
        setUserRole(decodedToken.role);
      }
    }
  }, []);

  useEffect(() => {
    const fetchDataBranch = async () => {
      try {
        const response = await axios.get(
          `${API_ENDPOINT_USER}/user-details/${userId}`
        );

        const branch = response.data;
        setUserBranch(branch.BranchOfficeId);
      } catch (error) {
        console.error("Error al obtener datos de usuario y su sucursal", error);
      }
    };

    fetchDataBranch();
  }, [userId, userBranch]);

  useEffect(() => {
    const fetchDataCompany = async () => {
      if (userBranch !== undefined) {
        try {
          const response = await axios.get(
            `${API_ENDPOINT_BRANCH}/branch-office-details/${userBranch}`
          );

          const company = response.data.CompanyId;
          setUserCompany(company);
        } catch (error) {
          console.error(
            "Error al obtener datos de la sucursal y su empresa",
            error
          );
        }
      }
    };

    fetchDataCompany();
  }, [userBranch, userCompany]);

  return (
    <ChakraProvider>
      <Box
        style={{
          maxHeight: "100%",
          overflowY: "scroll",
          padding: "1rem",
        }}
      >
        <Wrap marginBottom="1rem">
          {statuses.map((status, i) => (
            <WrapItem key={i}>
              <Button
                borderColor="black"
                borderWidth="2px"
                onClick={() => {
                  toast({
                    title: `${userEmail} | ${
                      parseInt(userRole) === 1
                        ? content[language].adm
                        : parseInt(userRole) === 2
                        ? content[language].gm
                        : parseInt(userRole) === 3
                        ? content[language].om
                        : parseInt(userRole) === 4
                        ? content[language].gmb
                        : parseInt(userRole) === 5
                        ? content[language].tb
                        : parseInt(userRole)
                    } `,
                    status: status,
                    isClosable: true,
                  });
                }}
              >
                <Text mb="0.5rem">
                  <InfoIcon />
                </Text>{" "}
              </Button>
            </WrapItem>
          ))}
        </Wrap>

        <Box bottom="1rem" left="1rem" fontWeight="bold"></Box>
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems="center"
          pb={4}
          justifyContent="flex-end"
        >
          {showButtonCompany && (
            <>
              <FormControl
                flex="1"
                mb={{ base: 2, md: 0 }}
                mr={{ base: 0, md: 2 }}
              >
                <InputGroup>
                  <Input
                    type="text"
                    name="name"
                    placeholder={content[language].search}
                    value={searchCompanies}
                    onChange={(e) => setSearchCompanies(e.target.value)}
                    maxW={{ base: "100%", md: "300px" }}
                  />
                </InputGroup>
              </FormControl>

              <Button
                bg={"gray.200"}
                color="black"
                _hover={{
                  bg: "gray.300",
                }}
                borderColor="black"
                borderWidth="2px"
                leftIcon={<BiPlus />}
                mt={{ base: 2, md: 0 }}
                w={{ base: "95%", md: "auto" }}
                onClick={handleIconClick}
              >
                {content[language].add}
              </Button>
            </>
          )}
        </Flex>

        <TableContainer
          style={{
            boxShadow:
              "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>{content[language].logo}</Th>
                <Th>{content[language].name}</Th>
                {showButtonCompany && <Th>{content[language].action}</Th>}
              </Tr>
            </Thead>
            <Tbody>
              {companies
                .filter((company) => {
                  const name = company.name.toLowerCase();
                  return name.includes(searchCompanies.toLowerCase());
                })
                .filter((company) => {
                  if (parseInt(userRole) === 2 || parseInt(userRole) === 3) {
                    // "General Manager" y "Operations Manager"
                    return company.id === userCompany;
                  } else if (
                    parseInt(userRole) === 4 ||
                    parseInt(userRole) === 5
                  ) {
                    // "General Manager(branch)" y "Operations Manager(branch)"
                    return company.id === userCompany;
                  } else {
                    return true;
                  }
                })
                .map((company) => (
                  <React.Fragment key={company.id}>
                    <Tr key={company.id}>
                      <Td>
                        <img
                          src={`${API_ENDPOINT_COMPANIES}/logos/${company.id}?timestamp=${logoTimestamp}`}
                          alt={company.name}
                          style={{ width: "120px", height: "50px" }}
                        />
                      </Td>

                      <Td>{company.name}</Td>
                      <Td>
                        {showButtonCompany && company.name !== "SIESA" && (
                          <>
                            <Tooltip label={content[language].trash}>
                              <Button
                                colorScheme="red"
                                size="sm"
                                mr={2}
                                onClick={() =>
                                  handleDeleteCompany(company.id, company.name)
                                }
                              >
                                <BiTrash />
                              </Button>
                            </Tooltip>
                            <Tooltip label={content[language].edit}>
                              <Button
                                colorScheme="blue"
                                size="sm"
                                onClick={() => handleEditCompany(company.id)}
                              >
                                <BiPencil />
                              </Button>
                            </Tooltip>
                          </>
                        )}
                      </Td>
                    </Tr>
                  </React.Fragment>
                ))}
            </Tbody>
          </Table>
        </TableContainer>

        <ModalAddCompany
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          fetchData={fetchData}
        />

        {deleteCompanyId && (
          <AlertDialog
            isOpen={isDeleteConfirmationModalOpen}
            leastDestructiveRef={cancelRef}
            onClose={handleCancelDelete}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  {content[language].confirmDeleteTitle}{" "}
                  <strong>{deleteCompanyName}</strong>
                </AlertDialogHeader>

                <AlertDialogBody>
                  {content[language].confirmDeleteMessage}
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={handleCancelDelete}>
                    {content[language].cancel}
                  </Button>
                  <Button
                    colorScheme="red"
                    onClick={handleConfirmDelete}
                    ml={3}
                  >
                    {content[language].delete}
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        )}

        {editCompanyId && (
          <ModalEditCompany
            isOpen={isEditModalOpen}
            onClose={() => {
              setEditCompanyId(null);
              setIsEditModalOpen(false);
              fetchData();
            }}
            companyId={editCompanyId}
            fetchData={fetchData}
            onEditSuccess={() => {
              setLogoTimestamp(Date.now());
            }}
            onEditSuccessCompany={handleEditCompanySuccess}
          />
        )}

        <Box position="relative" padding="10">
          <Divider />
        </Box>

        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems="center"
          pb={4}
          justify="space-between"
        >
          {showButtonCompany && (
            <>
              <Select
                placeholder={content[language].select}
                maxW={{ base: "100%", md: "250px" }}
                mb={{ base: 2, md: 0 }}
                mr={{ base: 0, md: 2 }}
                value={selectedCompany}
                onChange={(e) => setSelectedCompany(e.target.value)}
              >
                {companies.map((company) => (
                  <option key={company.id} value={company.name}>
                    {company.name}
                  </option>
                ))}
              </Select>
            </>
          )}

          <FormControl flex="1" mb={{ base: 2, md: 0 }} mr={{ base: 0, md: 2 }}>
            <InputGroup>
              <Input
                type="text"
                name="name"
                placeholder={content[language].searchbranchoffice}
                value={searchBranch}
                onChange={(e) => setSearchBranch(e.target.value)}
                maxW={{ base: "100%", md: "300px" }}
              />
            </InputGroup>
          </FormControl>

          {showButtonBranch && (
            <Button
              bg={"gray.200"}
              color="black"
              _hover={{
                bg: "gray.300",
              }}
              borderColor="black"
              borderWidth="2px"
              leftIcon={<BiPlus />}
              mt={{ base: 2, md: 0 }}
              w={{ base: "95%", md: "auto" }}
              onClick={handleIconClickBranch}
            >
              {content[language].addBranch}
            </Button>
          )}
        </Flex>

        <TableContainer
          style={{
            boxShadow:
              "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>{content[language].branchoffice}</Th>
                <Th>{content[language].name}</Th>
                {showButtonBranch && <Th>{content[language].action}</Th>}
              </Tr>
            </Thead>
            <Tbody>
              {branchs
                .filter((branch) => {
                  const name = branch.name.toLowerCase();
                  const searchByBranch = name.includes(
                    searchBranch.toLowerCase()
                  );
                  if (selectedCompany === "") {
                    return searchByBranch;
                  } else {
                    const searchByCompany =
                      branch.Company.name === selectedCompany;
                    return searchByBranch && searchByCompany;
                  }
                })
                .filter((branch) => {
                  // "General Manager" y "Operations Manager"
                  if (parseInt(userRole) === 2 || parseInt(userRole) === 3) {
                    // "General Manager" y "Operations Manager"
                    return branch.Company.id === userCompany;
                  } else if (
                    parseInt(userRole) === 4 ||
                    parseInt(userRole) === 5
                  ) {
                    // "General Manager(branch)" y "Operations Manager(branch)"
                    return branch.id === userBranch;
                  } else {
                    return true;
                  }
                })
                .map((branch) => (
                  <Tr key={branch.id}>
                    <Td>{branch.name}</Td>
                    <Td>{branch.Company ? branch.Company.name : ""}</Td>
                    <Td>
                      {showButtonBranch &&
                        branch.Company &&
                        branch.Company.name !== "SIESA" && (
                          <>
                            <Tooltip label={content[language].trash}>
                              <Button
                                colorScheme="red"
                                size="sm"
                                mr={2}
                                onClick={() =>
                                  handleDeleteBranch(branch.id, branch.name)
                                }
                              >
                                <BiTrash />
                              </Button>
                            </Tooltip>
                            <Tooltip label={content[language].edit}>
                              <Button
                                colorScheme="blue"
                                size="sm"
                                mr={2}
                                onClick={() => handleEditBranch(branch.id)}
                              >
                                <BiPencil />
                              </Button>
                            </Tooltip>
                          </>
                        )}
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>

        <ModalAddBranch
          isOpen={isModalOpenBranch}
          onClose={handleCloseModalBranch}
          fetchDataBranch={fetchDataBranch}
        />

        {deleteBranchId && (
          <AlertDialog
            isOpen={isDeleteConfirmationModalOpenBranch}
            leastDestructiveRef={cancelRef}
            onClose={handleCancelDeleteBranch}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  {content[language].confirmDeleteTitle}{" "}
                  <strong>{deleteBranchName}</strong>
                </AlertDialogHeader>

                <AlertDialogBody>
                  {content[language].confirmDeleteMessageBranch}
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={handleCancelDeleteBranch}>
                    {content[language].cancel}
                  </Button>
                  <Button
                    colorScheme="red"
                    onClick={handleConfirmDeleteBranch}
                    ml={3}
                  >
                    {content[language].delete}
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        )}

        {editBranchId && (
          <ModalEditBranch
            isOpen={isEditModalOpenBranch}
            onClose={() => {
              setEditBranchId(null);
              setIsEditModalOpenBranch(false);
              fetchDataBranch();
            }}
            branchToEditId={editBranchId}
            fetchDataBranch={fetchDataBranch}
          />
        )}
      </Box>
    </ChakraProvider>
  );
};

export default CompanyDashboard;
