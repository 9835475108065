import React, { useState, useEffect } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Select,
} from "@chakra-ui/react";
import axios from "axios";
import { useLanguage } from "./LanguageContext";

const content = {
  en: {
    titleBranchOffice: "Edit Branch Office",
    selectCompany: "Select Company",
    name: "Name of branch office:",
    cancel: "Cancel",
    save: "Save",
    titleEdit: "Branch edited",
    errors: {
      validation: "Please enter values for all fields.",
      edit: "Error editing branch office. Please try again.",
      nameRequired: "Please enter the name of the branch office.",
      selectedCompany: "Please select a company.",
    },
    success: {
      edit: "The branch office has been edited successfully.",
    },
  },
  es: {
    titleBranchOffice: "Editar Sucursal",
    selectCompany: "Seleccione una Empresa",
    name: "Nombre de la sucursal:",
    cancel: "Cancelar",
    save: "Guardar",
    titleEdit: "Sucursal editada",
    errors: {
      validation: "Por favor, ingrese valores para todos los campos.",
      edit: "Error al editar la sucursal. Por favor, inténtelo de nuevo.",
      nameRequired: "Por favor, ingrese el nombre de la sucursal.",
      selectedCompany: "Por favor, seleccione una empresa.",
    },
    success: {
      edit: "La sucursal se ha editado correctamente.",
    },
  },
};

const ModalEditBranchOffice = ({
  isOpen,
  onClose,
  fetchDataBranch,
  branchToEditId,
}) => {
  const API_ENDPOINT_COMPANIES = "https://api-almacenamiento.in/companies";
  const API_ENDPOINT_BRANCH = "https://api-almacenamiento.in/branch-offices";
  const toast = useToast();
  const { language } = useLanguage();

  const [formData, setFormData] = useState({
    name: "",
  });
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchCompanies = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT_COMPANIES}/getAllCompanies`
      );
      setCompanies(response.data);
    } catch (error) {
      console.error("Error al buscar empresas:", error);
    }
  };

  const fetchBranchDetails = async (branchId) => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT_BRANCH}/branch-office-details/${branchId}`
      );
      const { name, companyId } = response.data;

      setFormData((prevData) => ({
        ...prevData,
        name: name || "",
      }));

      setSelectedCompany(companyId);
    } catch (error) {
      console.error("Error al obtener los detalles de la sucursal:", error);
    }
  };

  useEffect(() => {
    if (isOpen && branchToEditId) {
      fetchBranchDetails(branchToEditId);
    }
  }, [isOpen, branchToEditId]);

  useEffect(() => {
    fetchCompanies();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const editBranchOffice = async () => {
    try {
      // Validación de campos requeridos
      if (!formData.name) {
        toast({
          title: "Error",
          description: content[language].errors.nameRequired,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (!selectedCompany) {
        toast({
          title: "Error",
          description: content[language].errors.selectedCompany,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      setLoading(true);

      const response = await axios.put(
        `${API_ENDPOINT_BRANCH}/updateBranchOffice/${branchToEditId}`,
        {
          name: formData.name,
          companyId: selectedCompany,
        }
      );

      if (response.status === 200) {
        toast({
          title: content[language].titleEdit,
          description: content[language].success.edit,
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        setFormData({
          name: "",
        });
        setSelectedCompany("");

        onClose && onClose();
      } else {
        toast({
          title: "Error",
          description: content[language].errors.edit,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error al editar sucursal:", error);
      console.error("Error de detalles:", error.response.data);
      toast({
        title: "Error",
        description: content[language].errors.edit,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }finally{
      setLoading(false);
    }
  };

  const handleClose = async () => {
    setFormData({
      name: "",
    });
    setSelectedCompany("");

    onClose && onClose();
    fetchDataBranch();
  };

  return (
    <Modal isOpen={isOpen} onClose={() => handleClose()}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg" fontWeight="bold">
          {content[language].titleBranchOffice}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box
            borderWidth="1px"
            rounded="lg"
            shadow="1px 1px 3px rgba(0,0,0,0.3)"
            maxWidth={800}
            p={6}
            m="10px auto"
            as="form"
          >
            <FormControl isRequired>
              <FormLabel htmlFor="name">{content[language].name}</FormLabel>
              <Input
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </FormControl>

            <FormControl mt={4} isRequired>
              <FormLabel htmlFor="company">
                {content[language].selectCompany}
              </FormLabel>
              <Select
                id="company"
                name="company"
                placeholder={content[language].selectCompany}
                value={selectedCompany}
                onChange={(e) => setSelectedCompany(e.target.value)}
              >
                {companies.map((company) => (
                  <option key={company.id} value={company.id}>
                    {company.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup w="100%">
            <Flex w="100%" justifyContent="flex-end">
              <Button
                onClick={() => handleClose()}
                colorScheme="gray"
                variant="outline"
                w="5rem"
                mr="2%"
              >
                {content[language].cancel}
              </Button>
              <Button
                onClick={editBranchOffice}
                colorScheme="blue"
                variant="solid"
                isLoading={loading}
              >
                {content[language].save}
              </Button>
            </Flex>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalEditBranchOffice;
