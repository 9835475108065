import React, { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  HStack,
  Button,
  useColorModeValue,
  ChakraProvider,
  Image,
  Select,
} from "@chakra-ui/react";
import { BiArrowToLeft } from "react-icons/bi";
import LogoMovil from "../images/logoSimple.png";
import LogoEscritorio from "../images/logoSiesa.png";
import { useLanguage } from "./LanguageContext";

const content = {
  en: {
    button: "Log out",
    back: "Back",
    exit: "Are you sure you want to leave the page?",
  },
  es: {
    button: "Cerrar sesión",
    back: "Atrás",
    exit: "¿Estás seguro que deseas abandonar la página?",
  },
};

const NavbarBlueprints = () => {
  const { language, changeLanguage } = useLanguage();
  const navigate = useNavigate();

  const onLanguageChange = (selectedLanguage) => {
    changeLanguage(selectedLanguage);
  };

  const handleLogout = useCallback(() => {
    localStorage.removeItem("token");
    navigate("/");
  }, [navigate]);

  useEffect(() => {
    let logoutTimer = setTimeout(handleLogout, 300000); // 5 minutos

    const resetLogoutTimer = () => {
      clearTimeout(logoutTimer); // Limpiar el temporizador existente
      logoutTimer = setTimeout(handleLogout, 300000); // Reiniciar el temporizador
    };

    const handleBeforeUnload = (event) => {
      resetLogoutTimer();
      event.returnValue = content[language].exit;
    };

    // Configurar escuchadores de eventos para restablecer el temporizador en la actividad del usuario
    document.addEventListener("mousemove", resetLogoutTimer);
    document.addEventListener("mousedown", resetLogoutTimer);
    document.addEventListener("keypress", resetLogoutTimer);
    document.addEventListener("touchstart", resetLogoutTimer);
    document.addEventListener("scroll", resetLogoutTimer);
    document.addEventListener("visibilitychange", resetLogoutTimer);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Limpiar escuchadores de eventos
      document.removeEventListener("mousemove", resetLogoutTimer);
      document.removeEventListener("mousedown", resetLogoutTimer);
      document.removeEventListener("keypress", resetLogoutTimer);
      document.removeEventListener("touchstart", resetLogoutTimer);
      document.removeEventListener("scroll", resetLogoutTimer);
      document.removeEventListener("visibilitychange", resetLogoutTimer);
      window.removeEventListener("beforeunload", handleBeforeUnload);
      clearTimeout(logoutTimer);
    };
  }, [handleLogout, language]);

  const handleBack = useCallback(() => {
    const userType = localStorage.getItem("user");
    const token = localStorage.getItem("token");

    let redirectTo = "/";
    if (userType === "client") {
      redirectTo = `/client/home?token=${token}`;
    } else if (userType === "admin") {
      redirectTo = `/admin/home?token=${token}`;
    }

    navigate(redirectTo);
  }, [navigate]);

  return (
    <ChakraProvider>
      <Box
        bg={useColorModeValue("gray.200", "gray.900")}
        px={4}
        style={{
          boxShadow:
            "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
        }}
      >
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <HStack spacing={8} alignItems={"center"}>
            <Button
              onClick={handleBack}
              color="black"
              fontWeight="bold"
              _hover={{
                bg: "gray.300",
                boxShadow:
                  "0 3px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.08)",
              }}
              bg="gray.200"
              size="sm"
              borderColor="black"
              borderWidth="2px"
              leftIcon={<BiArrowToLeft />}
              style={{
                boxShadow:
                  "0 3px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.08)",
              }}
            >
              {content[language].back}
            </Button>
            <Box display={{ base: "none", md: "block" }}>
              <Image
                src={LogoEscritorio}
                alt="Logo"
                width={{ base: 150, md: 120 }}
                display={{ base: "none", md: "block" }}
              />
            </Box>
            <Box display={{ base: "block", md: "none" }}>
              <Image
                src={LogoMovil}
                alt="Logo"
                width={{ base: 150, md: 120 }}
                display={{ base: "block", md: "none" }}
              />
            </Box>
          </HStack>

          <Flex alignItems={"center"} ml="auto">
            <Select
              value={language}
              onChange={(event) => onLanguageChange(event.target.value)}
              width="100px"
              mr={2}
              bg={"white"}
            >
              <option value="es">ES</option>
              <option value="en">EN</option>
            </Select>
          </Flex>
        </Flex>
      </Box>

      <Box bg="#f8b603" height="6px" width="100%" />
    </ChakraProvider>
  );
};

export default NavbarBlueprints;
