import React from "react";
import {
  Button,
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useMediaQuery,
} from "@chakra-ui/react";
import Login from "./Login";
import { useLanguage } from "../componets/LanguageContext";

export default function Modal({ isOpen, onClose }) {
  const [isSmallerThanMd] = useMediaQuery("(max-width: 768px)");

  const { language } = useLanguage();

  const content = {
    en: {
      buttonClose: "Close",
    },
    es: {
      buttonClose: "Cerrar",
    },
  };

  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={isSmallerThanMd}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Login />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>{content[language].buttonClose}</Button>
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
}
