import { useState, useEffect } from "react";
import {
  Progress,
  Box,
  ButtonGroup,
  Button,
  Heading,
  Flex,
  FormControl,
  GridItem,
  FormLabel,
  Input,
  Select,
  InputGroup,
  InputRightElement,
  ChakraProvider,
  useToast,
} from "@chakra-ui/react";
import { useLanguage } from "./LanguageContext";
import axios from "axios";

const Form1 = ({ handleChange, userData }) => {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const { language } = useLanguage();

  const content = {
    en: {
      userRegistration: "User Registration",
      firstName: "First Name:",
      lastName: "Last Name:",
      emailAddress: "Email Address:",
      enterEmail: "Enter email: example@example.com",
      enterFirstName: "Enter First Name",
      enterLastName: "Enter Last Name",
      password: "Password:",
      enterPassword: "Enter Password",
      hide: "Hide",
      show: "Show",
    },
    es: {
      userRegistration: "Registro de Usuario",
      firstName: "Nombre:",
      lastName: "Apellido:",
      emailAddress: "Dirección de Correo Electrónico:",
      enterEmail: "Ingrese el correo electrónico: example@example.com",
      enterFirstName: "Ingrese el Nombre",
      enterLastName: "Ingrese el Apellido",
      password: "Contraseña:",
      enterPassword: "Ingrese la Contraseña",
      hide: "Ocultar",
      show: "Mostrar",
    },
  };

  return (
    <ChakraProvider>
      <Heading w="100%" textAlign={"center"} fontWeight="normal" mb="2%">
        {content[language].userRegistration}
      </Heading>
      <Flex>
        <FormControl mr="5%" isRequired>
          <FormLabel htmlFor="first-name" fontWeight={"normal"}>
            {content[language].firstName}
          </FormLabel>
          <Input
            id="first-name"
            name="firstname"
            value={userData.firstname}
            onChange={handleChange}
            placeholder={content[language].enterFirstName}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel htmlFor="last-name" fontWeight={"normal"}>
            {content[language].lastName}
          </FormLabel>
          <Input
            id="last-name"
            name="lastname"
            value={userData.lastname}
            onChange={handleChange}
            placeholder={content[language].enterLastName}
          />
        </FormControl>
      </Flex>
      <FormControl mt="2%" isRequired>
        <FormLabel htmlFor="email" fontWeight={"normal"}>
          {content[language].emailAddress}
        </FormLabel>
        <Input
          id="email"
          name="email"
          type="email"
          value={userData.email}
          onChange={handleChange}
          placeholder={content[language].enterEmail}
        />
      </FormControl>

      <FormControl isRequired>
        <FormLabel htmlFor="password" fontWeight="normal" mt="2%">
          {content[language].password}
        </FormLabel>
        <InputGroup size="md">
          <Input
            pr="4.5rem"
            type={show ? "text" : "password"}
            name="password"
            value={userData.password}
            onChange={handleChange}
            placeholder={content[language].enterPassword}
          />
          <InputRightElement width="5rem">
            <Button h="1.75rem" size="sm" onClick={handleClick}>
              {show ? content[language].hide : content[language].show}
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>
    </ChakraProvider>
  );
};

const Form2 = ({ handleChange, userData, setUserData, onClose }) => {
  const API_ENDPOINT_BRANCH = "https://api-almacenamiento.in/branch-offices";

  const API_ENDPOINT_ROLE = "https://api-almacenamiento.in/roles";
  const API_ENDPOINT_STATUS = "https://api-almacenamiento.in/statuses";
  const { language } = useLanguage();
  const toast = useToast();

  const content = {
    en: {
      userDetails: "User Details",
      status: "Status:",
      selectStatus: "Select Status",
      active: "Active",
      inactive: "Inactive",
      role: "Role:",
      selectRole: "Select Role",
      admin: "Admin",
      user: "User",
      branchOffice: "Branch Office:",
      selectBranchOffice: "Select Branch Office",
      errorData: "Error loading data",
      errorData2: "Error loading data. Please try again.",
      errorData3: "Error loading data:",
    },
    es: {
      userDetails: "Detalles del Usuario",
      status: "Estado:",
      selectStatus: "Seleccionar Estado",
      active: "Activo",
      inactive: "Inactivo",
      role: "Rol:",
      selectRole: "Seleccionar Rol",
      admin: "Administrador",
      user: "Usuario",
      branchOffice: "Sucursal:",
      selectBranchOffice: "Seleccionar Sucursal",
      errorData: "Error al cargar datos",
      errorData2: "Error al cargar los datos. Inténtelo de nuevo.",
      errorData3: "Error durante la carga de datos:",
    },
  };

  const loadData = async () => {
    let branchOffices, roles, statuses;

    try {
      const branchOfficesResponse = await axios.get(
        `${API_ENDPOINT_BRANCH}/getAllBranchOffices`
      );
      const rolesResponse = await axios.get(`${API_ENDPOINT_ROLE}/getAllRoles`);
      const statusesResponse = await axios.get(
        `${API_ENDPOINT_STATUS}/getAllStatuses`
      );

      if (
        branchOfficesResponse.status === 200 &&
        rolesResponse.status === 200 &&
        statusesResponse.status === 200
      ) {
        branchOffices = branchOfficesResponse.data;
        roles = rolesResponse.data;
        statuses = statusesResponse.data;

        setUserData((prevData) => ({
          ...prevData,
          branchOffices,
          roles,
          statuses,
          branchId: prevData.branchId || branchOffices[1]?.id || null,
          roleId: prevData.roleId || roles[2]?.id || null,
          statusId: prevData.statusId || statuses[1]?.id || null,
        }));
      } else {
        console.error(content[language].errorData);
        toast({
          title: "Error",
          description: content[language].errorData2,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error(content[language].errorData3, error);
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ChakraProvider>
      <Heading w="100%" textAlign={"center"} fontWeight="normal" mb="2%">
        {content[language].userDetails}
      </Heading>

      <FormControl as={GridItem} colSpan={6} isRequired>
        <FormLabel
          htmlFor="status"
          fontSize="sm"
          fontWeight="md"
          color="gray.700"
        >
          {content[language].status}
        </FormLabel>
        <Select
          id="status"
          name="statusId"
          onChange={handleChange}
          autoComplete="status"
          placeholder={content[language].selectStatus}
          focusBorderColor="brand.400"
          shadow="sm"
          size="sm"
          w="full"
          rounded="md"
        >
          {userData.statuses.map((status) => (
            <option key={status.id} value={status.id}>
              {status.name}
            </option>
          ))}
        </Select>
      </FormControl>

      <FormControl as={GridItem} colSpan={6} isRequired>
        <FormLabel
          htmlFor="role"
          fontSize="sm"
          fontWeight="md"
          color="gray.700"
          _dark={{
            color: "gray.50",
          }}
          mt="2%"
        >
          {content[language].role}
        </FormLabel>
        <Select
          id="role"
          name="roleId"
          autoComplete="role"
          onChange={handleChange}
          placeholder={content[language].selectRole}
          focusBorderColor="brand.400"
          shadow="sm"
          size="sm"
          w="full"
          rounded="md"
        >
          {userData.roles.map((role) => (
            <option key={role.id} value={role.id}>
              {role.name}
            </option>
          ))}
        </Select>
      </FormControl>

      <FormControl as={GridItem} colSpan={6} isRequired>
        <FormLabel
          htmlFor="branch-office"
          fontSize="sm"
          fontWeight="md"
          color="gray.700"
          _dark={{
            color: "gray.50",
          }}
          mt="2%"
        >
          {content[language].branchOffice}
        </FormLabel>

        <Select
          id="branch-office"
          name="branchId"
          autoComplete="branch-office"
          onChange={handleChange}
          placeholder={content[language].selectBranchOffice}
          focusBorderColor="brand.400"
          shadow="sm"
          size="sm"
          w="full"
          rounded="md"
        >
          {userData.branchOffices.map((branchOffice) => (
            <option key={branchOffice.id} value={branchOffice.id}>
              {branchOffice.name}
            </option>
          ))}
        </Select>
      </FormControl>
    </ChakraProvider>
  );
};

export default function Register({ onClose }) {
  const API_ENDPOINT_USER = "https://api-almacenamiento.in/users";
  const toast = useToast();
  const [step, setStep] = useState(1);
  const [progress, setProgress] = useState(50);
  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    branchId: null,
    statusId: null,
    roleId: null,
    branchOffices: [],
    roles: [],
    statuses: [],
  });

  const { language } = useLanguage();

  const content = {
    en: {
      back: "Back",
      next: "Next",
      submit: "Submit",
      accountCreatedTitle: "Account Created",
      accountCreatedDescription: "Your account has been successfully created.",
      requiredFieldsError: "Please fill in all required fields.",
      dropdownsError: "Please select values for all dropdowns.",
      genericError: "Failed to create the account. Please try again.",
      emailAlreadyExists: "The email already exists.",
      validEmail: "Invalid email format.",
      emailNotExistsMessage: "Email allowed for registration.",
    },
    es: {
      back: "Regresar",
      next: "Siguiente",
      submit: "Enviar",
      accountCreatedTitle: "Cuenta Creada",
      accountCreatedDescription: "Su cuenta ha sido creada con éxito.",
      requiredFieldsError: "Por favor, complete todos los campos obligatorios.",
      dropdownsError:
        "Por favor, seleccione valores para todas las listas desplegables.",
      genericError: "Error al crear la cuenta. Por favor, inténtelo de nuevo.",
      emailAlreadyExists: "El correo electrónico ya existe.",
      validEmail: "Formato de correo electrónico no es válido.",
      emailNotExistsMessage: "Correo electrónico permitido para el registro.",
    },
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateEmail = (email) => {
    // Formato del correo electrónico
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return {
        isValid: false,
        error: content[language].validEmail,
      };
    }
    return {
      isValid: true,
      error: null,
    };
  };

  const handleNextStep = async () => {
    if (step === 1) {
      // Validar que los campos obligatorios estén llenos
      if (
        !userData.firstname ||
        !userData.lastname ||
        !userData.email ||
        !userData.password
      ) {
        toast({
          title: "Error",
          description: content[language].requiredFieldsError,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      // Validar el formato del correo electrónico
      const emailValidation = validateEmail(userData.email);
      if (!emailValidation.isValid) {
        toast({
          title: "Error",
          description: emailValidation.error,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      // Validar si el correo electrónico ya existe
      try {
        const response = await axios.get(
          `${API_ENDPOINT_USER}/user-emails-details`,
          {
            params: {
              email: userData.email,
            },
          }
        );

        // Verificar si el correo electrónico ya existe
        if (response.status === 200 && response.data) {
          toast({
            title: "Error",
            description: content[language].emailAlreadyExists,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        setStep(2);
        setProgress(100);
      }
    }
  };

  const handleSubmit = async () => {
    if (!userData.branchId || !userData.statusId || !userData.roleId) {
      toast({
        title: "Error",
        description: content[language].dropdownsError,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (
      !userData.firstname ||
      !userData.lastname ||
      !userData.email ||
      !userData.password ||
      !validateEmail(userData.email)
    ) {
      toast({
        title: "Error",
        description: content[language].requiredFieldsError,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // Validar que los selects estén seleccionados
    if (!userData.statusId || !userData.roleId || !userData.branchId) {
      toast({
        title: "Error",
        description: content[language].dropdownsError,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // Hacer la llamada a la API solo si la validación pasa
    try {
      const response = await axios.post(`${API_ENDPOINT_USER}/register`, {
        firstname: userData.firstname,
        lastname: userData.lastname,
        email: userData.email,
        password: userData.password,
        branchId: userData.branchId,
        statusId: userData.statusId,
        roleId: userData.roleId,
      });

      if (response.status === 201) {
        toast({
          title: content[language].accountCreatedTitle,
          description: content[language].accountCreatedDescription,
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        onClose && onClose();
      } else {
        toast({
          title: "Error",
          description: content[language].genericError,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error al crear la cuenta:", error);
      toast({
        title: "Error",
        description: content[language].genericError,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handlePreviousStep = () => {
    if (step === 2) {
      setStep(1);
      setProgress(50);
    }
  };

  return (
    <ChakraProvider>
      <Box
        borderWidth="1px"
        rounded="lg"
        shadow="1px 1px 3px rgba(0,0,0,0.3)"
        maxWidth={800}
        p={6}
        m="10px auto"
        as="form"
      >
        <Progress
          hasStripe
          value={progress}
          mb="5%"
          mx="5%"
          isAnimated
        ></Progress>
        {step === 1 ? (
          <Form1 handleChange={handleChange} userData={userData} />
        ) : step === 2 ? (
          <Form2
            handleChange={handleChange}
            userData={userData}
            setUserData={setUserData}
          />
        ) : null}
        <ButtonGroup mt="5%" w="100%">
          <Flex w="100%" justifyContent="space-between">
            <Flex>
              <Button
                onClick={handlePreviousStep}
                isDisabled={step === 1}
                colorScheme="blue"
                variant="solid"
                w="5rem"
                mr="5%"
              >
                {content[language].back}
              </Button>
              <Button
                w="5rem"
                onClick={step === 1 ? handleNextStep : handleSubmit}
                colorScheme="blue"
                variant="outline"
              >
                {step === 2 ? content[language].submit : content[language].next}
              </Button>
            </Flex>
          </Flex>
        </ButtonGroup>
      </Box>
    </ChakraProvider>
  );
}
