import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  InputRightElement,
  InputGroup,
  Alert,
  AlertIcon,
  ChakraProvider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useLanguage } from "../componets/LanguageContext";

const content = {
  en: {
    title: "Sign in to your account",
    email: "Email:",
    password: "Password:",
    forgotPassword: "Forgot password?",
    button: "Sign in",
    emailValid: 'Please enter a valid email "example@example.com"',
    emailValidMandatory: "The email field is required.",
    passwordValidMandatory: "The password field is required.",
    loginError: "The email or password is incorrect.",
    loginErrorServe: "Failed to connect to the server.",
    userDisabled: "This user is not active. Please contact the administrator.",
    cancel: "Cancel",
    send: "Send",
    example: "example@example.com",
    sendEmail: "Password recovery email sent",
    sendEmailError: "Error sending the password recovery email.",
    success: {
      sendEmail: "Password recovery email sent.",
    },
    error: {
      sendEmail: "Failed to send the password recovery email.",
    },
  },
  es: {
    title: "Iniciar sesión en su cuenta",
    email: "Correo electrónico:",
    password: "Contraseña:",
    forgotPassword: "¿Olvidaste tu contraseña?",
    button: "Iniciar sesión",
    emailValid:
      'Por favor, ingresa un correo electrónico válido "ejemplo@ejemplo.com".',
    emailValidMandatory: "El campo de correo electrónico es obligatorio.",
    passwordValidMandatory: "El campo de contraseña es obligatorio.",
    loginError: "El correo electrónico o contraseña son incorrectos.",
    loginErrorServe: "Fallo al conectar con el servidor.",
    userDisabled:
      "Este usuario no está activo. Por favor, contacte al administrador.",
    cancel: "Cancelar",
    send: "Enviar",
    example: "ejemplo@ejemplo.com",
    sendEmail: "Correo electrónico de recuperación de contraseña enviado",
    success: {
      sendEmail: "Correo electrónico de recuperación de contraseña enviado.",
    },
    error: {
      sendEmail:
        "Fallo al enviar el correo electrónico de recuperación de contraseña.",
    },
  },
};

const Login = () => {
  const API_ENDPOINT_USER = "https://api-almacenamiento.in/users";
  const API_ENDPOINT_HOST = "https://api-almacenamiento.in";
  const { language } = useLanguage();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailModal, setEmailModal] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginError, setLoginError] = useState("");
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [emailErrorModal, setEmailErrorModal] = useState("");

  const toast = useToast();

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    setEmailError("");
  };

  const handleEmailChanges = (event) => {
    const { value } = event.target;
    setEmailModal(value);
    setEmailErrorModal("");
  };

  const handlePasswordChange = (event) => {
    const { value } = event.target;
    setPassword(value);
    setPasswordError("");
  };

  const handleEmailBlur = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError(content[language].emailValid);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let hasError = false;

    if (email.trim() === "") {
      setEmailError(content[language].emailValidMandatory);
      hasError = true;
    }

    if (password.trim() === "") {
      setPasswordError(content[language].passwordValidMandatory);
      hasError = true;
    }

    if (hasError) {
      return;
    }

    axios
      .post(`${API_ENDPOINT_USER}/login`, {
        email: email,
        password: password,
      })
      .then((res) => {
        if (res.status === 200) {
          const { user, passwordMatch } = res.data;
          if (user) {
            localStorage.setItem("token", user.auth_token);
            if (passwordMatch && user.RoleId === 1 && user.StatusId === 1) {
              localStorage.setItem("user", "admin");
              navigate(`/admin/home?token=${user.auth_token}`);
            } else if (!passwordMatch) {
              setLoginError(content[language].loginError);
            } else if (user.StatusId === 2) {
              setLoginError(content[language].userDisabled);
            } else {
              localStorage.setItem("user", "client");
              navigate(`/client/home?token=${user.auth_token}`);
            }
          } else {
            setLoginError(content[language].loginError);
          }
        } else {
          setLoginError(content[language].loginError);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoginError(content[language].loginErrorServe);
      });
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setEmailModal("");
  };

  const handleSubmitPassword = (event) => {
    event.preventDefault();

    let hasErrorModal = false;

    // Validación del correo electrónico
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(emailModal)) {
      setEmailErrorModal(content[language].emailValid);
      hasErrorModal = true;
    } else {
      setEmailErrorModal(""); // Limpiar el mensaje de error si el correo es válido
    }

    if (emailModal === undefined || emailModal.trim() === "") {
      setEmailErrorModal(content[language].emailValidMandatory);
      hasErrorModal = true;
    }

    if (hasErrorModal) {
      return;
    }

    axios
      .post(`${API_ENDPOINT_HOST}/change-password/send-email`, {
        email: emailModal,
      })
      .then((res) => {
        if (res.status === 200) {
          toast({
            title: content[language].sendEmail,
            description: content[language].success.sendEmail,
            status: "success",
            duration: 3000,
            isClosable: true,
          });

          closeModal();
        } else {
          toast({
            title: content[language].sendEmailError,
            description: content[language].error.sendEmail,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      })
      .catch((err) => {
        console.log(
          "Error al enviar el email de recueperación de contraseña. " + err
        );
      });
  };

  return (
    <ChakraProvider>
      <Flex
        minH={"60vh"}
        align={"center"}
        justify={"center"}
        bg={useColorModeValue("gray.50", "gray.800")}
      >
        <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
          <Stack align={"center"}>
            <Heading fontSize={"4xl"}>{content[language].title}</Heading>
          </Stack>
          <Box
            rounded={"lg"}
            bg={useColorModeValue("white", "gray.700")}
            boxShadow={"lg"}
            p={8}
          >
            <form onSubmit={handleSubmit}>
              <Stack spacing={4}>
                <FormControl id="email" isInvalid={!!emailError}>
                  <FormLabel>{content[language].email}</FormLabel>
                  <Input
                    type="email"
                    placeholder={content[language].example}
                    value={email}
                    onChange={handleEmailChange}
                    onBlur={handleEmailBlur}
                  />
                  {emailError && (
                    <Alert status="error" mt={2}>
                      <AlertIcon />
                      {emailError}
                    </Alert>
                  )}
                </FormControl>
                <FormControl id="password" isInvalid={!!passwordError}>
                  <FormLabel>{content[language].password}</FormLabel>
                  <InputGroup>
                    <Input
                      type={showPassword ? "text" : "password"}
                      onChange={handlePasswordChange}
                    />
                    <InputRightElement>
                      {showPassword ? (
                        <ViewIcon
                          h={5}
                          w={5}
                          onClick={handleTogglePassword}
                          cursor="pointer"
                        />
                      ) : (
                        <ViewOffIcon
                          h={5}
                          w={5}
                          onClick={handleTogglePassword}
                          cursor="pointer"
                        />
                      )}
                    </InputRightElement>
                  </InputGroup>
                  {passwordError && (
                    <Alert status="error" mt={2}>
                      <AlertIcon />
                      {passwordError}
                    </Alert>
                  )}
                </FormControl>
                <Stack spacing={10}>
                  <Stack
                    direction={{ base: "column", sm: "row" }}
                    align={{ base: "start", sm: "end" }}
                    justify={"space-between"}
                  >
                    <Text
                      color={"blue.400"}
                      textAlign={{ base: "left", sm: "right" }}
                      onClick={openModal}
                      cursor="pointer"
                    >
                      {content[language].forgotPassword}
                    </Text>
                  </Stack>
                  <Button
                    type="submit"
                    bg={"gray.200"}
                    color="black"
                    borderColor="black"
                    borderWidth="2px"
                    _hover={{
                      bg: "gray.300",
                    }}
                  >
                    {content[language].button}
                  </Button>

                  {loginError && (
                    <Alert status="error" mt={4}>
                      <AlertIcon />
                      {loginError}
                    </Alert>
                  )}
                </Stack>
              </Stack>
            </form>

            {/* Modal de recuperación de contraseña */}
            <Modal isOpen={modalOpen} onClose={closeModal}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>{content[language].forgotPassword}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl id="emailModal" isInvalid={!!emailErrorModal}>
                    <FormLabel>{content[language].email}</FormLabel>
                    <Input
                      type="email"
                      placeholder={content[language].example}
                      value={emailModal}
                      onChange={handleEmailChanges}
                    />
                    {emailErrorModal && (
                      <Alert status="error" mt={2}>
                        <AlertIcon />
                        {emailErrorModal}
                      </Alert>
                    )}
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="submit"
                    bg={"gray.200"}
                    color="black"
                    borderColor="black"
                    borderWidth="2px"
                    _hover={{
                      bg: "gray.300",
                    }}
                    onClick={handleSubmitPassword}
                  >
                    {content[language].send}
                  </Button>
                  <Button onClick={closeModal} ml={2}>
                    {content[language].cancel}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Box>
        </Stack>
      </Flex>
    </ChakraProvider>
  );
};

export default Login;
