import React from "react";
import NavbarPanel from "../../componets/NavbarPanel";
import DashboardClient from "../../componets/DashboardClient";
/* import Footer from "../../componets/Footer"; */
import { ChakraProvider } from "@chakra-ui/react";
import { LanguageProvider } from "../../componets/LanguageContext";

function HomeClient() {
  return (
    <ChakraProvider>
      <LanguageProvider>
        <NavbarPanel />
        <DashboardClient />
       {/*  <Footer /> */}
      </LanguageProvider>
    </ChakraProvider>
  );
}

export default HomeClient;
