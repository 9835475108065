import React from "react";
import NavbarBlueprints from "../../componets/NavbarBlueprints";
import Blueprint from "../../componets/Blueprints";
import { ChakraProvider } from "@chakra-ui/react";
import { LanguageProvider } from "../../componets/LanguageContext";

function Blueprints() {
  return (
    <ChakraProvider>
      <LanguageProvider>
        <NavbarBlueprints />
        <Blueprint />
      </LanguageProvider>
    </ChakraProvider>
  );
}

export default Blueprints;
