import React, { useState, useEffect } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  ChakraProvider,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import axios from "axios";
import { useLanguage } from "./LanguageContext";

const content = {
  en: {
    titleUser: "Edit User",
    firstname: "First Name:",
    lastname: "Last Name:",
    email: "Email Address:",
    selectRole: "Select Role",
    selectStatus: "Select Status",
    selectBranch: "Select Branch Office",
    selectCompany: "Select Company",
    branchoffice: "Branch Office:",
    status: "Status:",
    role: "Role:",
    cancel: "Cancel",
    save: "Save",
    company: "Company:",
    titleEdit: "Edited user",
    password: "Password:",
    errors: {
      password: "Please enter a password as this field is required.",
      validation: "Please select values ​​for all options.",
      update: "Error updating user. Please try again.",
      requiredField: "This field is required.",
      firstname: "Please enter a name as this field is required.",
      lastname: "Please enter a last name as this field is required.",
      company: "Please select a company.",
      branch: "Please select a branch.",
      status: "Please select a status.",
      role: "Please select a role.",
    },
    success: {
      update: "The user has been successfully updated.",
    },
    hide: "Hide",
    show: "Show",
  },
  es: {
    titleUser: "Editar Usuario",
    firstname: "Nombre:",
    lastname: "Apellido:",
    email: "Dirección de Correo Electrónico:",
    selectRole: "Seleccionar Rol",
    selectStatus: "Seleccionar Estado",
    selectBranch: "Seleccionar Sucursal",
    selectCompany: "Seleccionar Empresa",
    branchoffice: "Sucursal:",
    status: "Estado:",
    role: "Rol:",
    cancel: "Cancelar",
    save: "Guardar",
    company: "Empresa:",
    titleEdit: "Usuario actualizado",
    password: "Contraseña:",
    errors: {
      password:
        "Por favor, ingrese una contraseña ya que este campo es obligatorio.",
      validation: "Por favor, seleccione valores para todas las opciones.",
      update: "Error al actualizar el usuario. Por favor, inténtelo de nuevo.",
      requiredField: "Este campo es obligatorio.",
      firstname:
        "Por favor, ingrese un nombre ya que este campo es obligatorio.",
      lastname:
        "Por favor, ingrese un apellido ya que este campo es obligatorio.",

      company: "Por favor, seleccione una empresa.",
      branch: "Por favor, seleccione una sucursal.",
      status: "Por favor, seleccione un estado.",
      role: "Por favor, seleccione un rol.",
    },
    success: {
      update: "El usuario se ha actualizado correctamente.",
    },
    hide: "Ocultar",
    show: "Mostrar",
  },
};

const ModalEditUser = ({ isOpen, onClose, userId }) => {
  const API_ENDPOINT_USER = "https://api-almacenamiento.in/users";
  const API_ENDPOINT_ROLE = "https://api-almacenamiento.in/roles";
  const API_ENDPOINT_COMPANIES = "https://api-almacenamiento.in/companies";
  const API_ENDPOINT_STATUS = "https://api-almacenamiento.in/statuses";
  const toast = useToast();
  const { language } = useLanguage();
  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    branchId: "",
    statusId: "",
    roleId: "",
    branchOffices: [],
    roles: [],
    statuses: [],
  });
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    // Realizar la llamada a la API solo cuando el modal se abre
    if (isOpen && !userData.isModalOpen) {
      const fetchData = async () => {
        try {
          // Obtener la información del usuario
          const userResponse = await axios.get(
            `${API_ENDPOINT_USER}/user-details/${userId}`
          );

          if (userResponse.status === 200) {
            const user = userResponse.data;
            console.log("🚀 ~ fetchData ~ user:", user);

            // Obtener la información de las compañías
            const companiesResponse = await axios.get(
              `${API_ENDPOINT_COMPANIES}/getAllCompanies`
            );
            const companies = companiesResponse.data;

            // Obtener los roles
            const rolesResponse = await axios.get(
              `${API_ENDPOINT_ROLE}/getAllRoles`
            );
            const roles = rolesResponse.data;

            // Obtener los estados
            const statusesResponse = await axios.get(
              `${API_ENDPOINT_STATUS}/getAllStatuses`
            );
            const statuses = statusesResponse.data;

            // Actualizar el estado con los datos obtenidos del usuario
            setUserData({
              ...userData,
              companies: companies,
              roles: roles,
              statuses: statuses,
              selectedCompany: user.companyId,
              firstname: user.firstname,
              lastname: user.lastname,
              email: user.email,
              password: "",
              branchId: user.branchId,
              statusId: user.statusId,
              roleId: user.roleId,
              branchOffices: [],
              isModalOpen: true,
            });
          } else {
            console.error("Error al recuperar los datos del usuario");
          }
        } catch (error) {
          console.error("Error al recuperar los datos del usuario:", error);
        }
      };

      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleCompanyChange = async (e) => {
    const companyId = e.target.value;

    // Obtener las sucursales de la compañía seleccionada
    const branchesResponse = await axios.get(
      `${API_ENDPOINT_COMPANIES}/${companyId}/branches`
    );
    const branches = branchesResponse.data;

    // Actualizar el estado de la compañía seleccionada y las sucursales
    setUserData((prevData) => ({
      ...prevData,
      selectedCompany: companyId,
      branchOffices: branches,
    }));
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    // Validar campos obligatorios
    if (!userData.firstname) {
      toast({
        title: "Error",
        description: content[language].errors.firstname,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!userData.lastname) {
      toast({
        title: "Error",
        description: content[language].errors.lastname,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!userData.password) {
      toast({
        title: "Error",
        description: content[language].errors.password,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!userData.selectedCompany) {
      toast({
        title: "Error",
        description: content[language].errors.company,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!userData.branchId) {
      toast({
        title: "Error",
        description: content[language].errors.branch,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!userData.statusId) {
      toast({
        title: "Error",
        description: content[language].errors.status,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!userData.roleId) {
      toast({
        title: "Error",
        description: content[language].errors.role,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await axios.put(
        `${API_ENDPOINT_USER}/update/${userId}`,
        {
          firstname: userData.firstname,
          lastname: userData.lastname,
          password: userData.password,
          BranchOfficeId: userData.branchId,
          StatusId: userData.statusId,
          RoleId: userData.roleId,
        }
      );

      if (response.status === 200) {
        toast({
          title: content[language].titleEdit,
          description: content[language].success.update,
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        onClose && onClose();
      } else {
        toast({
          title: "Error",
          description: content[language].errors.update,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      // Manejar errores de la solicitud de actualización del usuario
      console.error("Error al actualizar usuario:", error);
      toast({
        title: "Error",
        description: content[language].errors.update,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <ChakraProvider>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="lg" fontWeight="bold">
            {content[language].titleUser}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              borderWidth="1px"
              rounded="lg"
              shadow="1px 1px 3px rgba(0,0,0,0.3)"
              maxWidth={800}
              p={6}
              m="10px auto"
              as="form"
            >
              <FormControl isRequired>
                <FormLabel htmlFor="firstname">
                  {content[language].firstname}
                </FormLabel>
                <Input
                  id="firstname"
                  name="firstname"
                  value={userData.firstname}
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl mt={2} isRequired>
                <FormLabel htmlFor="lastname">
                  {content[language].lastname}
                </FormLabel>
                <Input
                  id="lastname"
                  name="lastname"
                  value={userData.lastname}
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl mt={2} isRequired>
                <FormLabel htmlFor="password">
                  {content[language].password}
                </FormLabel>
                <Flex>
                  <Input
                    id="password"
                    name="password"
                    value={userData.password}
                    onChange={handleChange}
                    type={showPassword ? "text" : "password"}
                  />
                  <Button onClick={toggleShowPassword} ml={2}>
                    {showPassword
                      ? content[language].hide
                      : content[language].show}
                  </Button>
                </Flex>
              </FormControl>

              <FormControl mt={2} isRequired>
                <FormLabel htmlFor="companyId">
                  {content[language].company}
                </FormLabel>
                <Select
                  id="companyId"
                  name="companyId"
                  value={userData.selectedCompany}
                  placeholder={content[language].selectCompany}
                  onChange={(e) => handleCompanyChange(e)}
                >
                  {userData.companies &&
                    userData.companies.map((company) => (
                      <option key={company.id} value={company.id}>
                        {company.name}
                      </option>
                    ))}
                </Select>
              </FormControl>

              <FormControl mt={2} isRequired>
                <FormLabel htmlFor="branchId">
                  {content[language].branchoffice}
                </FormLabel>
                <Select
                  id="branchId"
                  name="branchId"
                  value={userData.branchId}
                  placeholder={content[language].selectBranch}
                  onChange={(e) => handleChange(e)}
                >
                  {userData.branchOffices.map((branch) => (
                    <option key={branch.id} value={branch.id}>
                      {branch.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl mt={2} isRequired>
                <FormLabel htmlFor="statusId">
                  {content[language].status}
                </FormLabel>
                <Select
                  id="statusId"
                  name="statusId"
                  placeholder={content[language].selectStatus}
                  value={userData.statusId}
                  onChange={handleChange}
                >
                  {userData.statuses.map((status) => (
                    <option key={status.id} value={status.id}>
                      {status.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl mt={2} isRequired>
                <FormLabel htmlFor="roleId">{content[language].role}</FormLabel>
                <Select
                  id="roleId"
                  name="roleId"
                  value={userData.roleId}
                  placeholder={content[language].selectRole}
                  onChange={handleChange}
                >
                  {userData.roles.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup w="100%">
              <Flex w="100%" justifyContent="flex-end">
                <Button
                  onClick={onClose}
                  colorScheme="blue"
                  variant="solid"
                  w="5rem"
                  mr="5%"
                >
                  {content[language].cancel}
                </Button>
                <Button
                  onClick={handleSubmit}
                  colorScheme="blue"
                  variant="outline"
                >
                  {content[language].save}
                </Button>
              </Flex>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};

export default ModalEditUser;
