import React, { useState, useEffect } from "react";
import Index from "./screens/Index";
import AdmHome from "./screens/admin/HomeAdmin";
import ClientHome from "./screens/client/HomeClient";
import Blueprints from "./screens/blueprints/Blueprints";

import {
  Routes,
  Route,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    // verificar si hay un token de autenticación almacenado en localStorage
    const storedToken = localStorage.getItem("token");
    return !!storedToken; // Devuelve true si hay un token almacenado, de lo contrario, devuelve false
  });

  useEffect(() => {
    setIsLoggedIn("isLoggedIn", isLoggedIn);
  }, [isLoggedIn]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route
          path="/admin/home"
          element={
            isLoggedIn ? (
              <AdmHome token={localStorage.getItem("token")} />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/client/home"
          element={
            isLoggedIn ? (
              <ClientHome token={localStorage.getItem("token")} />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/data/blueprints/:type/:id/:name"
          element={isLoggedIn ? <Blueprints /> : <Navigate to="/" />}
        />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
