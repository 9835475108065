import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  ChakraProvider,
  FormControl,
  InputGroup,
  Input,
  Button,
  Flex,
  Tooltip,
  Box,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import { BiPlus, BiTrash, BiPencil } from "react-icons/bi";
import axios from "axios";
import { useLanguage } from "../componets/LanguageContext";
import ModalRegister from "../componets/ModalRegister";
import ModalEditUser from "../componets/ModalEditUser";

const content = {
  en: {
    register: "Register user",
    search: "Search user",
    fullname: "Full Name",
    email: "Email",
    role: "Role",
    branchoffice: "Branch office",
    company: "Company",
    action: "Action",
    trash: "Delete",
    edit: "Edit",
    confirmDeleteTitle: "Confirm Delete",
    confirmDeleteMessage: "Are you sure you want to delete this user?",
    cancel: "Cancel",
    delete: "Delete",
    login: "Last login",
    dateInvalid: "Invalid date",
  },
  es: {
    register: "Registrar usuario",
    search: "Buscar usuario",
    fullname: "Nombre completo",
    email: "Correo electrónico",
    role: "Rol",
    branchoffice: "Sucursal",
    company: "Empresa",
    action: "Acción",
    trash: "Eliminar",
    edit: "Editar",
    confirmDeleteTitle: "Confirmar eliminación",
    confirmDeleteMessage: "¿Estás seguro de que quieres eliminar este usuario?",
    cancel: "Cancelar",
    delete: "Eliminar",
    login: "Último acceso",
    dateInvalid: "Fecha inválida",
  },
};

const UserDashboard = () => {
  const API_ENDPOINT_USER = "https://api-almacenamiento.in/users";

  const { language } = useLanguage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const [users, setUsers] = useState([]);
  const [searchUser, setSearchUser] = useState("");
  const [deleteUserId, setDeleteUserId] = useState(null);
  const cancelRef = useRef();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editUserId, setEditUserId] = useState(null);
  const [deleteUserName, setDeleteUserName] = useState(null);

  const handleEditUser = (userId) => {
    setEditUserId(userId);
    setIsEditModalOpen(true);
  };

  const handleIconClick = () => {
    setIsModalOpen(true);
  };

  const handleDeleteUser = (userId, userName) => {
    setDeleteUserId(userId);
    setIsDeleteConfirmationModalOpen(true);
    setDeleteUserName(userName);
  };

  const handleCancelDelete = () => {
    setDeleteUserId(null);
    setIsDeleteConfirmationModalOpen(false);
  };

  const handleConfirmDelete = () => {
    axios
      .delete(`https://api-almacenamiento.in/users/delete/${deleteUserId}`)
      .then((response) => {
        console.log("Usuario eliminado: ", response.data);
        setDeleteUserId(null);
        setIsDeleteConfirmationModalOpen(false);
        fetchData();
      })
      .catch((error) => {
        console.error("Error al eliminar usuario: ", error);
      });
  };

  useEffect(() => {
    axios
      .get(`${API_ENDPOINT_USER}/user-details`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error("Error al obtener datos de usuarios ", error);
      });
  }, []);

  const fetchData = () => {
    axios
      .get(`${API_ENDPOINT_USER}/user-details`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error("Error al obtener datos de usuarios ", error);
      });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, []);

  function formatLastLogin(lastLogin) {
    const options = {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    if (!lastLogin) {
      return content[language].dateInvalid;
    }

    const loginTime = new Date(lastLogin);
    const now = new Date();
    const diff = now - loginTime;
    const diffMinutes = Math.floor(diff / (1000 * 60));
    const diffHours = Math.floor(diff / (1000 * 60 * 60));

    if (diffHours < 1) {
      if (diffMinutes < 1) {
        return "hace unos segundos";
      } else {
        return `hace ${diffMinutes} minutos`;
      }
    } else if (diffHours < 24) {
      return `hace ${diffHours} horas`;
    } else if (diffHours < 48) {
      return "ayer";
    } else {
      return loginTime.toLocaleString("en-US", options);
    }
  }

  // Actualizar cada minuto
  setInterval(() => {
    const elements = document.querySelectorAll(".last-login");
    elements.forEach((element) => {
      const lastLogin = element.dataset.lastLogin;
      if (lastLogin) {
        element.textContent = formatLastLogin(lastLogin);
      }
    });
  }, 60000);

  return (
    <ChakraProvider>
      <Box
        style={{
          maxHeight: "100%",
          overflowY: "scroll",
          padding: "1rem",
        }}
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems="center"
          pb={4}
          justify="space-between"
        >
          <FormControl flex="1" mb={{ base: 2, md: 0 }} mr={{ base: 0, md: 2 }}>
            <InputGroup>
              <Input
                type="text"
                name="name"
                placeholder={content[language].search}
                value={searchUser}
                onChange={(e) => setSearchUser(e.target.value)}
                maxW={{ base: "100%", md: "300px" }}
              />
            </InputGroup>
          </FormControl>
          <Button
            bg={"gray.200"}
            color="black"
            _hover={{
              bg: "gray.300",
            }}
            borderColor="black"
            borderWidth="2px"
            leftIcon={<BiPlus />}
            w={{ base: "95%", md: "auto" }}
            mt={{ base: 2, md: 0 }}
            onClick={handleIconClick}
          >
            {content[language].register}
          </Button>
        </Flex>

        <TableContainer
          style={{
            boxShadow:
              "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
          }}
          pb={4}
        >
          <Table size="sm" variant='striped' colorScheme="gray">
            <Thead>
              <Tr>
                <Th>{content[language].fullname}</Th>
                <Th>{content[language].email}</Th>
                <Th>{content[language].role}</Th>
                <Th>{content[language].login}</Th>
                <Th>{content[language].branchoffice}</Th>
                <Th>{content[language].company}</Th>
                <Th>{content[language].action}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {users
                .filter((user) => {
                  const fullName = user.name.toLowerCase();
                  return fullName.includes(searchUser.toLowerCase());
                })
                .map((user) => (
                  <Tr key={user.id}>
                    <Td>{user.name}</Td>
                    <Td>{user.email}</Td>
                    <Td>{user.Role.name}</Td>
                    <Td>{formatLastLogin(user.lastLogin)}</Td>
                    <Td>{user.BranchOffice.name}</Td>
                    <Td>{user.BranchOffice.Company.name}</Td>
                    <Td>
                      {user.Role && user.Role.name !== "Administrator" && (
                        <>
                          <Tooltip label={content[language].trash}>
                            <Button
                              colorScheme="red"
                              size="sm"
                              mr={2}
                              onClick={() =>
                                handleDeleteUser(user.id, user.name)
                              }
                            >
                              <BiTrash />
                            </Button>
                          </Tooltip>
                          <Tooltip label={content[language].edit}>
                            <Button
                              colorScheme="blue"
                              size="sm"
                              mr={2}
                              onClick={() => handleEditUser(user.id)}
                            >
                              <BiPencil />
                            </Button>
                          </Tooltip>
                        </>
                      )}
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>

        {deleteUserId && (
          <AlertDialog
            isOpen={isDeleteConfirmationModalOpen}
            leastDestructiveRef={cancelRef}
            onClose={handleCancelDelete}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  {content[language].confirmDeleteTitle}{" "}
                  <strong>{deleteUserName}</strong>
                </AlertDialogHeader>

                <AlertDialogBody>
                  {content[language].confirmDeleteMessage}
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={handleCancelDelete}>
                    {content[language].cancel}
                  </Button>
                  <Button
                    colorScheme="red"
                    onClick={handleConfirmDelete}
                    ml={3}
                  >
                    {content[language].delete}
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        )}

        {editUserId && (
          <ModalEditUser
            isOpen={isEditModalOpen}
            onClose={() => {
              setEditUserId(null);
              setIsEditModalOpen(false);
              fetchData();
            }}
            userId={editUserId}
          />
        )}

        <ModalRegister isOpen={isModalOpen} onClose={handleCloseModal} />
      </Box>
    </ChakraProvider>
  );
};

export default UserDashboard;
