import React, { useState, useEffect } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  ChakraProvider,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ListItem,
  ListIcon,
  List,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import { useLanguage } from "./LanguageContext";
import { IoDocumentOutline, IoCloseSharp } from "react-icons/io5";

const content = {
  en: {
    titleProject: "Edit Project",
    name: "Name of project:",
    description: "Description of project:",
    uploadFiles: "Upload blueprints:",
    cancel: "Cancel",
    save: "Save",
    selectBranch: "Select branch office to which it belongs:",
    selectBranchOffice: "Select branch office",
    selectCategory: "Select category:",
    selectCategories: "Select category",
    selectCompany: "Select company to which it belongs:",
    selectCompanies: "Select company",
    titleEdit: "Project edited",
    errors: {
      validation: "Please select values for all dropdowns.",
      edit: "Error editing project. Please try again.",
      pdf: "Only PDF files are allowed",
      nameRequired: "Please provide a name for the project.",
      companyRequired: "Please select a company for the project.",
      branchRequired: "Please select a branch for the project.",
      categoryRequired: "Please select a category for the project.",
    },
    success: {
      edit: "The project has been edited successfully.",
    },
    remove: "Remove",
  },
  es: {
    titleProject: "Editar Proyecto",
    name: "Nombre del proyecto:",
    description: "Descripción del proyecto:",
    uploadFiles: "Subir planos:",
    cancel: "Cancelar",
    save: "Guardar",
    selectBranch: "Seleccione sucursal a la que pertenece:",
    selectBranchOffice: "Seleccione sucursal",
    selectCategory: "Seleccione la categoría:",
    selectCategories: "Seleccionar categoría",
    selectCompany: "Seleccione la empresa a la que pertenece:",
    selectCompanies: "Seleccionar empresa",
    titleEdit: "Project editado",

    errors: {
      validation: "Por favor, seleccione valores para todas las opciones.",
      edit: "Error al editar el proyecto. Por favor, inténtelo de nuevo.",
      pdf: "Sólo se permiten archivos PDF",
      nameRequired: "Por favor, proporcione un nombre para el proyecto.",
      companyRequired: "Por favor, seleccione una empresa para el proyecto.",
      branchRequired: "Por favor, seleccione una sucursal para el proyecto.",
      categoryRequired: "Por favor, seleccione una categoría para el proyecto.",
    },
    success: {
      edit: "El proyecto se ha editado correctamente.",
    },
    remove: "Eliminar",
  },
};

const ModalEditProject = ({ isOpen, onClose, projectId, fetchData }) => {
  const API_ENDPOINT_PROJECT = "https://api-almacenamiento.in/projects";
  const API_ENDPOINT_COMPANY = "https://api-almacenamiento.in/companies";
  const API_ENDPOINT_CATEGORY = "https://api-almacenamiento.in/categories";

  const toast = useToast();
  const { language } = useLanguage();

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    companyId: "",
    branchId: "",
    categoryId: "",
    files: [],
  });

  const [companyOptions, setCompanyOptions] = useState([]);
  const [branchOptions, setBranchOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileError, setFileError] = useState("");

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        // Verificar si tenemos un equipmentId
        if (projectId) {
          const response = await axios.get(
            `${API_ENDPOINT_PROJECT}/project-details/${projectId}`
          );

          // Establecer los detalles específicos del equipo en el formulario
          setFormData({
            name: response.data.name || "",
            description: response.data.description || "",
            /* companyId: response.data.Company?.id || "",
            branchId: response.data.BranchOffice?.id || "",
            categoryId: response.data.Category?.id || "", */
            files: [],
          });
        }
      } catch (error) {
        console.error("Error al obtener detalles:", error);
      }
    };

    fetchProjectDetails();
  }, [isOpen, projectId]);

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const response = await axios.get(
          `${API_ENDPOINT_COMPANY}/getAllCompanies`
        );
        setCompanyOptions(response.data);
      } catch (error) {
        console.error("Error al obtener los detalles de la empresa:", error);
      }
    };

    fetchCompanyDetails();
  }, [isOpen]);

  useEffect(() => {
    const fetchBranchDetails = async () => {
      try {
        if (!formData.companyId) {
          // Si companyId está ausente, puedes manejar esto según tus requisitos.

          setBranchOptions([]); // Establecer opciones de sucursal como vacías
          return;
        }

        const response = await axios.get(
          `${API_ENDPOINT_COMPANY}/${formData.companyId}/branches`
        );
        setBranchOptions(response.data);
      } catch (error) {
        console.error("Error al obtener los detalles de la sucursal:", error);

        setBranchOptions([]);
      }
    };

    fetchBranchDetails();
  }, [formData.companyId]);

  useEffect(() => {
    const fetchCategoryDetails = async () => {
      try {
        const response = await axios.get(
          `${API_ENDPOINT_CATEGORY}/getAllCategories`
        );
        setCategoryOptions(response.data);
      } catch (error) {
        console.error("Error al obtener los detalles de la categoria:", error);
      }
    };

    fetchCategoryDetails();
  }, [isOpen]);

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const companiesResponse = await axios.get(
          `${API_ENDPOINT_COMPANY}/getAllCompanies`
        );
        setCompanyOptions(companiesResponse.data);
      } catch (error) {
        console.error("Error al obtener detalles:", error);
      }
    };

    fetchProjectDetails();
  }, [isOpen, projectId]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "files" && (!files || files.length === 0)) {
      console.error("Los archivos son nulos o no están definidos.");
      return;
    }

    if (name === "files") {
      const isValidFileType = Array.from(files).every(
        (file) => file.type === "application/pdf"
      );

      if (!isValidFileType) {
        setFileError(content[language].errors.pdf);
        return;
      }

      setFileError("");
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]:
        name === "files" ? [...prevData.files, ...Array.from(files)] : value,
    }));
  };

  const removeFile = (fileName) => {
    setFormData((prevData) => ({
      ...prevData,
      files: prevData.files.filter((file) => file.name !== fileName),
    }));
  };

  const editProject = async () => {
    try {
      // Validación de campos requeridos
      if (!formData.name) {
        toast({
          title: "Error",
          description: content[language].errors.nameRequired,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (!formData.companyId) {
        toast({
          title: "Error",
          description: content[language].errors.companyRequired,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (!formData.branchId) {
        toast({
          title: "Error",
          description: content[language].errors.branchRequired,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (!formData.categoryId) {
        toast({
          title: "Error",
          description: content[language].errors.categoryRequired,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      setLoading(true);

      // Create FormData
      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name);
      formDataToSend.append("description", formData.description);
      formDataToSend.append("companyId", formData.companyId);
      formDataToSend.append("branchId", formData.branchId);
      formDataToSend.append("categoryId", formData.categoryId);

      if (formData.files) {
        formData.files.forEach((file, index) => {
          formDataToSend.append("blueprint", file);
        });
      } else {
        formDataToSend.append("blueprint", "[]");
      }

      const response = await axios.put(
        `${API_ENDPOINT_PROJECT}/updateProject/${projectId}`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response);

      if (response.status === 200) {
        toast({
          title: content[language].titleEdit,
          description: content[language].success.edit,
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        setFormData({
          name: "",
          description: "",
          companyId: "",
          branchId: "",
          categoryId: "",
          files: [],
        });

        onClose && onClose();
      } else {
        toast({
          title: "Error",
          description: content[language].errors.edit,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: content[language].errors.edit,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = async () => {
    setFormData({
      name: "",
      description: "",
      companyId: "",
      branchId: "",
      categoryId: "",
      files: [],
    });

    onClose && onClose();
    fetchData();
  };

  return (
    <ChakraProvider>
      <Modal isOpen={isOpen} onClose={() => handleClose()}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="lg" fontWeight="bold">
            {content[language].titleProject}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              borderWidth="1px"
              rounded="lg"
              shadow="1px 1px 3px rgba(0,0,0,0.3)"
              maxWidth={800}
              p={6}
              m="10px auto"
              as="form"
            >
              <FormControl isRequired>
                <FormLabel htmlFor="name">{content[language].name}</FormLabel>
                <Input
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel htmlFor="description">
                  {content[language].description}
                </FormLabel>
                <Textarea
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl mt={4} isRequired>
                <FormLabel htmlFor="companyId">
                  {content[language].selectCompany}
                </FormLabel>
                <Select
                  id="companyId"
                  name="companyId"
                  placeholder={content[language].selectCompanies}
                  value={formData.companyId}
                  onChange={handleChange}
                >
                  {companyOptions.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl mt={4} isRequired>
                <FormLabel htmlFor="branchId">
                  {content[language].selectBranch}
                </FormLabel>
                <Select
                  id="branchId"
                  name="branchId"
                  placeholder={content[language].selectBranchOffice}
                  value={formData.branchId}
                  onChange={handleChange}
                >
                  {branchOptions.map((branch) => (
                    <option key={branch.id} value={branch.id}>
                      {branch.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl mt={4} isRequired>
                <FormLabel htmlFor="categoryId">
                  {content[language].selectCategory}
                </FormLabel>
                <Select
                  id="categoryId"
                  name="categoryId"
                  placeholder={content[language].selectCategories}
                  value={formData.categoryId}
                  onChange={handleChange}
                >
                  {categoryOptions.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl mt={4}>
                <FormLabel htmlFor="files">
                  {content[language].uploadFiles}
                </FormLabel>
                <Input
                  type="file"
                  id="files"
                  name="files"
                  onChange={handleChange}
                  accept=".pdf"
                  multiple
                />
                {fileError && (
                  <Text mt={2} color="red.700">
                    {fileError}
                  </Text>
                )}
              </FormControl>

              <List>
                {formData.files.map((file, index) => (
                  <ListItem key={index}>
                    <ListIcon as={IoDocumentOutline} color="black" />
                    {file.name} ({file.type})
                    <Button
                      size="xs"
                      borderRadius="full"
                      colorScheme="red"
                      onClick={() => removeFile(file.name)}
                      ml={2}
                    >
                      <IoCloseSharp />
                    </Button>
                  </ListItem>
                ))}
              </List>
            </Box>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup w="100%">
              <Flex w="100%" justifyContent="flex-end">
                <Button
                  onClick={() => handleClose()}
                  colorScheme="gray"
                  variant="outline"
                  w="5rem"
                  mr="2%"
                >
                  {content[language].cancel}
                </Button>
                <Button
                  onClick={editProject}
                  colorScheme="blue"
                  variant="solid"
                  isLoading={loading}
                >
                  {content[language].save}
                </Button>
              </Flex>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};

export default ModalEditProject;
