import {
  Box,
  Flex,
  Heading,
  Image,
  Stack,
  useColorModeValue,
  ChakraProvider,
  Link,
} from "@chakra-ui/react";
/* import { FaArrowRight } from "react-icons/fa"; */
import { useLanguage } from "./LanguageContext";
import scanGif from "../images/scan.gif";
import print from "../images/print.jpg";

const Index = () => {
  const { language } = useLanguage();

  const content = {
    en: {
      heading: "SIESA",
      subheading:
        "In construction and engineering, proper plan management and effective team collaboration are critical to the success of any project; Siesa App is an innovative web application designed for efficient plan management and seamless collaboration.",
      features1:
        "1. Centralized Plan Storage: Siesa App allows you to store all your project plans in a single place accessible from any device with an Internet connection. Forget about cumbersome physical filing cabinets or disorganized local servers.",
      features2:
        "2. Quick Access via QR: Imagine the convenience of accessing your plans on the construction site or in a project meeting by simply scanning a QR code. Siesa App gives you this functionality for a hassle-free experience.",
      buttonLabel: "Visit",
    },
    es: {
      heading: "SIESA",
      subheading:
        "En la construcción y la ingeniería, la correcta gestión de planos y la colaboración efectiva entre equipos son fundamentales para el éxito de cualquier proyecto; Siesa App es una innovadora aplicación web diseñada para la gestión eficiente de planos y la colaboración sin complicaciones.",
      features1:
        "1. Almacenamiento Centralizado de Planos: Siesa App te permite guardar todos tus planos de proyectos en un único lugar accesible desde cualquier dispositivo con conexión a Internet. Olvídate de los engorrosos archivadores físicos o los servidores locales desorganizados.",
      features2:
        "2. Acceso Rápido a Través de QR: Imagina la comodidad de acceder a tus planos en el sitio de construcción o en una reunión de proyecto con solo escanear un código QR. Siesa App te brinda esta funcionalidad para una experiencia sin complicaciones.",
      buttonLabel: "Visitar",
    },
  };

  return (
    <ChakraProvider>
      <Box
        maxW="7xl"
        mx="auto"
        px={{
          base: "0",
          lg: "12",
        }}
        py={{
          base: "0",
          lg: "12",
        }}
      >
        <Stack
          direction={{
            base: "column-reverse",
            lg: "row",
          }}
          spacing={{
            base: "0",
            lg: "20",
          }}
        >
          <Box
            width={{
              lg: "sm",
            }}
            transform={{
              base: "translateY(-50%)",
              lg: "none",
            }}
            bg={{
              base: useColorModeValue("gray.50", "gray.700"),
              lg: "transparent",
            }}
            mx={{
              base: "6",
              md: "8",
              lg: "0",
            }}
            px={{
              base: "6",
              md: "8",
              lg: "0",
            }}
            py={{
              base: "6",
              md: "8",
              lg: "12",
            }}
          >
            <Stack
              spacing={{
                base: "8",
                lg: "10",
              }}
            >
              <Stack
                spacing={{
                  base: "2",
                  lg: "4",
                }}
              >
                <Heading size="xl" color={"black"} style={{ marginTop: 0 }}>
                  <Link
                    color={useColorModeValue("black")}
                    fontWeight="bold"
                    href="https://siesa.com.pa/"
                    target="_blank"
                  >
                    {content[language].heading}
                  </Link>
                </Heading>
                <Heading size="sm" fontWeight="normal" textAlign={"justify"}>
                  {content[language].subheading}
                  <br></br>
                  <br></br>

                  {content[language].features1}
                  <br></br>
                  <br></br>
                  {content[language].features2}
                </Heading>
              </Stack>
            </Stack>
          </Box>
          <Flex flex="1" overflow="hidden">
            <Image
              src={scanGif}
              alt="Scann"
              maxH="450px"
              minW="300px"
              objectFit="cover"
              flex="1"
            />
            <Image
              display={{
                base: "none",
                sm: "initial",
              }}
              src={print}
              alt="Print"
              maxH="450px"
              objectFit="cover"
            />
          </Flex>
        </Stack>
      </Box>
    </ChakraProvider>
  );
};

export default Index;
