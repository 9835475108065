import React, { useState, useEffect } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  ChakraProvider,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ListItem,
  ListIcon,
  List,
} from "@chakra-ui/react";
import axios from "axios";
import { useLanguage } from "./LanguageContext";
import { IoDocumentOutline, IoCloseSharp } from "react-icons/io5";

const content = {
  en: {
    titleEquipment: "Add Equipment",
    name: "Name of equipment:",
    description: "Description of equipment:",
    selectProject: "Select project to which it belongs:",
    selectProy: "Select project",
    uploadFiles: "Upload blueprints:",
    cancel: "Cancel",
    save: "Save",
    titleAdd: "Added equipment",
    selectBranch: "Select branch office to which it belongs:",
    selectBranchOffice: "Select branch office",
    selectCompany: "Select company to which it belongs:",
    selectCompanies: "Select company",
    area: "Equipment area:",
    errors: {
      validation: "Please select values for all dropdowns.",
      add: "Error adding equipment. Please try again.",
      nameRequired: "Please enter the name of the equipment.",
      projectRequired: "Please select a project for the equipment.",
      blueprintRequired: "Please upload at least one blueprint.",
      companyRequired: "Please select a company.",
      branchRequired: "Please select a branch office.",
    },
    success: {
      add: "The team has been added successfully.",
    },
    remove: "Remove",
  },
  es: {
    titleEquipment: "Agregar Equipo",
    name: "Nombre del equipo:",
    description: "Descripción del equipo:",
    selectProject: "Seleccione proyecto al que pertenece:",
    selectProy: "Seleccionar proyecto",
    uploadFiles: "Subir planos:",
    cancel: "Cancelar",
    save: "Guardar",
    titleAdd: "Equipo agregado",
    selectBranch: "Seleccione sucursal a la que pertenece:",
    selectBranchOffice: "Seleccione sucursal",
    selectCompany: "Seleccione la empresa a la que pertenece:",
    selectCompanies: "Seleccionar empresa",
    area: "Área del equipo:",
    errors: {
      validation: "Por favor, seleccione valores para todas las opciones.",
      add: "Error al agregar el equipo. Por favor, inténtelo de nuevo.",
      nameRequired: "Por favor, ingrese el nombre del equipo.",
      projectRequired: "Por favor, seleccione un proyecto para el equipo.",
      blueprintRequired: "Por favor, cargue al menos un plano.",
      companyRequired: "Por favor, seleccione una empresa.",
      branchRequired: "Por favor, seleccione una sucursal.",
    },
    success: {
      add: "El equipo se ha agregado correctamente.",
    },
    remove: "Eliminar",
  },
};

const ModalAddEquipment = ({ isOpen, onClose, fetchData }) => {
  const API_ENDPOINT_PROJECT = "https://api-almacenamiento.in/projects";
  const API_ENDPOINT_EQUIPMENT = "https://api-almacenamiento.in/equipment";
  const API_ENDPOINT_COMPANIES = "https://api-almacenamiento.in/companies";
  const toast = useToast();
  const { language } = useLanguage();

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    area: "",
    companyId: "",
    branchId: "",
    projectId: "",
    files: [],
  });
  const [loading, setLoading] = useState(false);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [branchOptions, setBranchOptions] = useState([]);

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const response = await axios.get(
          `${API_ENDPOINT_COMPANIES}/getAllCompanies`
        );
        setCompanyOptions(response.data);
      } catch (error) {
        console.error("Error al obtener los detalles de la empresa:", error);
      }
    };

    fetchCompanyDetails();
  }, [isOpen]);

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const response = await axios.get(
          `${API_ENDPOINT_COMPANIES}/getAllCompanies`
        );
        setCompanyOptions(response.data);
      } catch (error) {
        console.error("Error al obtener los detalles de la empresa:", error);
      }
    };

    fetchCompanyDetails();
  }, []);

  useEffect(() => {
    const fetchBranchDetails = async () => {
      try {
        if (!formData.companyId) {
          setBranchOptions([]);
          return;
        }

        const response = await axios.get(
          `${API_ENDPOINT_COMPANIES}/${formData.companyId}/branches`
        );

        setBranchOptions(response.data || []);
      } catch (error) {
        console.error("Error al obtener los detalles de la sucursal:", error);
        setBranchOptions([]);
      }
    };

    fetchBranchDetails();
  }, [formData.companyId]);

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        if (!formData.branchId) {
          setProjectOptions([]);
          return;
        }

        const response = await axios.get(
          `${API_ENDPOINT_PROJECT}/project-details-branch/${formData.branchId}`
        );

        setProjectOptions(response.data || []);
      } catch (error) {
        console.error("Error al obtener los detalles del proyecto:", error);
        setProjectOptions([]);
      }
    };

    if (formData.branchId) {
      fetchProjectDetails();
    }
  }, [formData.branchId]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "files" ? Array.from(files) : value,
    }));
  };

  const removeFile = (fileName) => {
    setFormData((prevData) => ({
      ...prevData,
      files: prevData.files.filter((file) => file.name !== fileName),
    }));
  };

  const addEquipment = async () => {
    try {
      // Validación de campos requeridos
      if (!formData.name) {
        toast({
          title: "Error",
          description: content[language].errors.nameRequired,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (!formData.companyId) {
        toast({
          title: "Error",
          description: content[language].errors.companyRequired,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (!formData.branchId) {
        toast({
          title: "Error",
          description: content[language].errors.branchRequired,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (!formData.projectId) {
        toast({
          title: "Error",
          description: content[language].errors.projectRequired,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (formData.files.length === 0) {
        toast({
          title: "Error",
          description: content[language].errors.blueprintRequired,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      setLoading(true);

      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name);
      formDataToSend.append("description", formData.description);
      formDataToSend.append("area", formData.area);
      formDataToSend.append("projectId", formData.projectId);

      formData.files.forEach((file, index) => {
        formDataToSend.append("blueprint", file);
      });

      console.log("Form Data to Send:", formDataToSend.area);

      const response = await axios.post(
        `${API_ENDPOINT_EQUIPMENT}/addEquipment`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        toast({
          title: content[language].titleAdd,
          description: content[language].success.add,
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        setFormData({
          name: "",
          description: "",
          area: "",
          projectId: "",
          files: [],
        });

        onClose && onClose();
      } else {
        toast({
          title: "Error",
          description: content[language].errors.add,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error al agregar equipo:", error);
      console.error("Detalles del error:", error.response.data);
      toast({
        title: "Error",
        description: content[language].errors.add,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = async () => {
    setFormData({
      name: "",
      description: "",
      area: "",
      companyId: "",
      branchId: "",
      projectId: "",
      files: [],
    });

    onClose && onClose();
    fetchData();
  };

  return (
    <ChakraProvider>
      <Modal isOpen={isOpen} onClose={() => handleClose()}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="lg" fontWeight="bold">
            {content[language].titleEquipment}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              borderWidth="1px"
              rounded="lg"
              shadow="1px 1px 3px rgba(0,0,0,0.3)"
              maxWidth={800}
              p={6}
              m="10px auto"
              as="form"
            >
              <FormControl isRequired>
                <FormLabel htmlFor="name">{content[language].name}</FormLabel>
                <Input
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel htmlFor="description">
                  {content[language].description}
                </FormLabel>
                <Textarea
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel htmlFor="area">{content[language].area}</FormLabel>
                <Input
                  id="area"
                  name="area"
                  value={formData.area}
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl mt={4} isRequired>
                <FormLabel htmlFor="companyId">
                  {content[language].selectCompany}
                </FormLabel>
                <Select
                  id="companyId"
                  name="companyId"
                  placeholder={content[language].selectCompanies}
                  value={formData.companyId}
                  onChange={handleChange}
                >
                  {companyOptions.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl mt={4} isRequired>
                <FormLabel htmlFor="branchId">
                  {content[language].selectBranch}
                </FormLabel>
                <Select
                  id="branchId"
                  name="branchId"
                  placeholder={content[language].selectBranchOffice}
                  value={formData.branchId}
                  onChange={handleChange}
                >
                  {branchOptions.map((branch) => (
                    <option key={branch.id} value={branch.id}>
                      {branch.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl mt={4} isRequired>
                <FormLabel htmlFor="projectId">
                  {content[language].selectProject}
                </FormLabel>
                <Select
                  id="projectId"
                  name="projectId"
                  placeholder={content[language].selectProy}
                  value={formData.projectId}
                  onChange={handleChange}
                >
                  {projectOptions.map((project) => (
                    <option key={project.id} value={project.id}>
                      {project.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl mt={4} isRequired>
                <FormLabel htmlFor="files">
                  {content[language].uploadFiles}
                </FormLabel>
                <Input
                  type="file"
                  id="files"
                  name="files"
                  onChange={handleChange}
                  accept=".pdf"
                  multiple
                />
              </FormControl>

              <List>
                {formData.files.map((file, index) => (
                  <ListItem key={index}>
                    <ListIcon as={IoDocumentOutline} color="black" />
                    {file.name} ({file.type})
                    <Button
                      size="xs"
                      borderRadius="full"
                      colorScheme="red"
                      onClick={() => removeFile(file.name)}
                      ml={2}
                    >
                      <IoCloseSharp />
                    </Button>
                  </ListItem>
                ))}
              </List>
            </Box>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup w="100%">
              <Flex w="100%" justifyContent="flex-end">
                <Button
                  onClick={() => handleClose()}
                  colorScheme="gray"
                  variant="outline"
                  w="5rem"
                  mr="2%"
                >
                  {content[language].cancel}
                </Button>
                <Button
                  onClick={addEquipment}
                  colorScheme="blue"
                  variant="solid"
                  isLoading={loading}
                >
                  {content[language].save}
                </Button>
              </Flex>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};

export default ModalAddEquipment;
