import React, { useState } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  ChakraProvider,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import { useLanguage } from "./LanguageContext";

const content = {
  en: {
    titleCompany: "Add Company",
    name: "Name of company:",
    uploadLogo: "Upload logo:",
    cancel: "Cancel",
    save: "Save",
    titleAdd: "Added company",
    errors: {
      validation: "Please enter values for all fields.",
      add: "Error adding company. Please try again.",
      nameRequired: "Please enter the name of the company.",
      logoRequired: "Please upload the company logo.",
      image: "Only image type files are allowed.",
    },
    success: {
      add: "The company has been added successfully.",
    },
    remove: "Remove",
  },
  es: {
    titleCompany: "Agregar Empresa",
    name: "Nombre de la empresa:",
    uploadLogo: "Subir logo:",
    cancel: "Cancelar",
    save: "Guardar",
    titleAdd: "Empresa añadida",
    errors: {
      validation: "Por favor, ingrese valores para todos los campos.",
      add: "Error al agregar la empresa. Por favor, inténtelo de nuevo.",
      nameRequired: "Por favor, ingrese el nombre de la empresa.",
      logoRequired: "Por favor, cargue el logo de la empresa.",
      image: "Sólo se permiten archivos tipo imagen.",
    },
    success: {
      add: "La empresa se ha agregado correctamente.",
    },
    remove: "Eliminar",
  },
};

const ModalAddCompany = ({ isOpen, onClose, fetchData }) => {
  const API_ENDPOINT_COMPANIES = "https://api-almacenamiento.in/companies";
  const toast = useToast();
  const { language } = useLanguage();
  const [formData, setFormData] = useState({
    name: "",
    logo: null,
  });
  const [logoError, setLogoError] = useState("");

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "logo" && (!files || files.length === 0)) {
      console.error("Los archivos son nulos o no están definidos.");
      return;
    }

    if (name === "logo") {
      const isValidLogoType = files[0].type.startsWith("image/");

      if (!isValidLogoType) {
        setLogoError(content[language].errors.image);
        return;
      }

      setLogoError("");
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "logo" ? files[0] : value,
    }));
  };

  const addCompany = async () => {
    try {
      if (!formData.name) {
        toast({
          title: "Error",
          description: content[language].errors.nameRequired,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (!formData.logo) {
        toast({
          title: "Error",
          description: content[language].errors.logoRequired,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name);
      formDataToSend.append("logo", formData.logo);

      const response = await axios.post(
        `${API_ENDPOINT_COMPANIES}/addCompany`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        toast({
          title: content[language].titleAdd,
          description: content[language].success.add,
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        setFormData({
          name: "",
          logo: null,
        });

        onClose && onClose();
      } else {
        toast({
          title: "Error",
          description: content[language].errors.add,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error al agregar la empresa:", error);
      toast({
        title: "Error",
        description: content[language].errors.add,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleClose = async () => {
    setFormData({
      name: "",
      logo: null,
    });

    setLogoError("");

    onClose && onClose();
    fetchData();
  };

  return (
    <ChakraProvider>
      <Modal isOpen={isOpen} onClose={() => handleClose()}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="lg" fontWeight="bold">
            {content[language].titleCompany}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              borderWidth="1px"
              rounded="lg"
              shadow="1px 1px 3px rgba(0,0,0,0.3)"
              maxWidth={800}
              p={6}
              m="10px auto"
              as="form"
            >
              <FormControl isRequired>
                <FormLabel htmlFor="name">{content[language].name}</FormLabel>
                <Input
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl mt={4} isRequired>
                <FormLabel htmlFor="logo">
                  {content[language].uploadLogo}
                </FormLabel>
                <Input
                  type="file"
                  id="logo"
                  name="logo"
                  onChange={handleChange}
                  accept="image/*"
                />
                {logoError && (
                  <Text mt={2} color="red.700">
                    {logoError}
                  </Text>
                )}
              </FormControl>
            </Box>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup w="100%">
              <Flex w="100%" justifyContent="flex-end">
                <Button
                  onClick={() => handleClose()}
                  colorScheme="gray"
                  variant="outline"
                  w="5rem"
                  mr="2%"
                >
                  {content[language].cancel}
                </Button>
                <Button onClick={addCompany} colorScheme="blue" variant="solid">
                  {content[language].save}
                </Button>
              </Flex>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};

export default ModalAddCompany;
