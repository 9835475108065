import React, { useState, useEffect } from "react";
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  ChakraProvider,
} from "@chakra-ui/react";
import { useLanguage } from "./LanguageContext";
import CompanyDashboard from "./CompanyDashboard";
import ProjectDashboard from "./ProjectDashboard";
import EquipmentDashboard from "./EquipmentDashboard";
import { useNavigate } from "react-router-dom";

export default function DashboardAdmin() {
  const { language } = useLanguage();
  const [key, setKey] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    // Verificar si hay un token en la URL
    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.has("token")) {
      navigate("/");
    }
  }, [navigate]);

  const content = {
    en: {
      company: "Company",
      project: "Project",
      equipment: "Equipment",
    },
    es: {
      company: "Empresa",
      project: "Proyecto",
      equipment: "Equipo",
    },
  };

  return (
    <ChakraProvider>
      <Tabs
        isFitted
        variant="enclosed"
        pt={3}
        onChange={(index) => setKey(index)}
      >
        <TabList mb="1em">
          <Tab _selected={{ borderBottom: "3px solid black" }}>
            {content[language].company}
          </Tab>
          <Tab _selected={{ borderBottom: "3px solid black" }}>
            {content[language].project}
          </Tab>
          <Tab _selected={{ borderBottom: "3px solid black" }}>
            {content[language].equipment}
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel key={key}>
            <CompanyDashboard />
          </TabPanel>
          <TabPanel key={key}>
            <ProjectDashboard />
          </TabPanel>
          <TabPanel key={key}>
            <EquipmentDashboard />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </ChakraProvider>
  );
}
