import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  ChakraProvider,
  FormControl,
  InputGroup,
  Input,
  Button,
  Flex,
  Tooltip,
  HStack,
  VStack,
  Box,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Spacer,
  Text,
  Select,
} from "@chakra-ui/react";
import {
  BiPlus,
  BiTrash,
  BiPencil,
  BiChevronDown,
  BiChevronUp,
} from "react-icons/bi";
import { FaDownload, FaEye } from "react-icons/fa";
import axios from "axios";
import QRCode from "qrcode.react";
import QRCode2 from "qrcode";
import jsPDF from "jspdf";
import { useLanguage } from "../componets/LanguageContext";
import ModalAddEquipment from "../componets/ModalAddEquipment";
import ModalEditEquipment from "../componets/ModalEditEquipment";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import siesaLogo from "../images/logoSiesa.png";
import ppcLogo from "../images/ppc.png";
import acetiLogo from "../images/aceti.png";
import pda from "../images/pro.jpg";

const content = {
  en: {
    trash: "Delete",
    edit: "Edit",
    action: "Action",
    nameProject: "Name of project",
    nameEquipment: "Name of equipment",
    nameBranch: "Branch office",
    nameCompany: "Company",
    addEquipment: "Add equipment",
    searchEquipment: "Search equipment",
    more: "More information",
    confirmDeleteTitle: "Confirm Delete",
    user: "User: ",
    confirmDeleteMessage: "Are you sure you want to delete this equipment?",
    cancel: "Cancel",
    delete: "Delete",
    download: "Download sticker",
    view: "View blueprints",
    select: "Select branch",
    selectArea: "Select area",
    area: "Area",
    noArea: "No area",
    number: "#",
  },
  es: {
    trash: "Eliminar",
    edit: "Editar",
    action: "Acción",
    nameProject: "Nombre del proyecto",
    nameEquipment: "Nombre del equipo",
    nameBranch: "Sucursal",
    nameCompany: "Empresa",
    addEquipment: "Agregar equipo",
    searchEquipment: "Buscar equipo",
    more: "Más información",
    user: "Usuario: ",
    confirmDeleteTitle: "Confirmar eliminación",
    confirmDeleteMessage: "¿Estás seguro de que quieres eliminar este equipo?",
    cancel: "Cancelar",
    delete: "Eliminar",
    download: "Descargar sticker",
    view: "Ver planos",
    select: "Seleccionar sucursal",
    selectArea: "Seleccionar área",
    area: "Área",
    noArea: "Sin área",
    number: "#",
  },
};

const EquipmentDashboard = () => {
  const API_ENDPOINT_BRANCH = "https://api-almacenamiento.in/branch-offices";
  const API_ENDPOINT_USER = "https://api-almacenamiento.in/users";
  const API_ENDPOINT_EQUIPMENT = "https://api-almacenamiento.in/equipment";
  /* const API_ENDPOINT_COMPANIES = "https://api-almacenamiento.in/companies"; */

  const { language } = useLanguage();
  const [equipments, setEquipments] = useState([]);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [searchEquipment, setSearchEquipment] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteEquipmentId, setDeleteEquipmentId] = useState(null);
  const cancelRef = useRef();
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const [editEquipmentId, setEditEquipmentId] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [showButtonEquipment, setShowButtonEquipment] = useState(true);
  const navigate = useNavigate();
  const [deleteEquipmentName, setDeleteEquipmentName] = useState(null);
  const [userRole, setUserRole] = useState("");
  const [userBranch, setUserBranch] = useState("");
  const [userCompany, setUserCompany] = useState("");
  const [userId, setUserId] = useState("");
  /*   const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(""); */
  const [branchs, setBranchs] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [showSelectEquipment, setShowSelectEquipment] = useState(true);

  const fetchData = () => {
    axios
      .get(`${API_ENDPOINT_EQUIPMENT}/equipment-details`)
      .then((response) => {
        setEquipments(response.data);
      })
      .catch((error) => {
        console.error("Error al obtener datos del equipo", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const toggleAccordion = (id) => {
    setActiveAccordion(activeAccordion === id ? null : id);
  };

  const handleIconClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchData();
  };

  const handleDeleteEquipment = (equipmentId, equipmentName) => {
    setDeleteEquipmentId(equipmentId);
    setDeleteEquipmentName(equipmentName);
    setIsDeleteConfirmationModalOpen(true);
  };

  const handleCancelDelete = () => {
    setDeleteEquipmentId(null);
    setIsDeleteConfirmationModalOpen(false);
  };

  const handleConfirmDelete = () => {
    axios
      .delete(`${API_ENDPOINT_EQUIPMENT}/deleteEquipment/${deleteEquipmentId}`)
      .then((response) => {
        setDeleteEquipmentId(null);
        setIsDeleteConfirmationModalOpen(false);
        fetchData();
      })
      .catch((error) => {
        console.error("Error al eliminar equipo:", error);
      });
  };

  /*   useEffect(() => {
    axios
      .get(`${API_ENDPOINT_COMPANIES}/getAllCompanies`)
      .then((response) => {
        setCompanies(response.data);
      })
      .catch((error) => {
        console.error("Error al obtener datos de las empresas", error);
      });
  }, []); */

  useEffect(() => {
    axios
      .get(`${API_ENDPOINT_BRANCH}/getAllBranchOffices`)
      .then((response) => {
        setBranchs(response.data);
      })
      .catch((error) => {
        console.error("Error al obtener datos de las sucursales", error);
      });
  }, []);

  const handleEditEquipment = (equipmentId) => {
    setEditEquipmentId(equipmentId);
    setIsEditModalOpen(true);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      // Decodificar el token
      const decodedToken = jwtDecode(token);
      if (decodedToken.role !== 1) {
        setShowButtonEquipment(false);
        setUserId(decodedToken.id);
        setUserRole(decodedToken.role);
        setShowSelectEquipment(false);
      } else {
        setUserId(decodedToken.id);
      }
    }
  }, []);

  const generatePDF = async (equipment) => {
    const pdf = new jsPDF({
      unit: "mm",
      format: [60, 45],
      orientation: "landscape",
    });

    const qrData = `${
      window.location.origin
    }/data/blueprints/${encodeURIComponent("equipment")}/${
      equipment.id
    }/${encodeURIComponent(equipment.name)}`;

    const qrOptions = {
      margin: 1,
    };
    const qrImage = await QRCode2.toDataURL(qrData, qrOptions);

    const qrWidth = 26;
    const qrHeight = qrWidth;
    pdf.addImage(qrImage, "JPEG", 2, 2, qrWidth, qrHeight);

    // Ajustar el tamaño de la letra
    pdf.setFontSize(7); // Aumentar el tamaño de la letra

    const pdfWidth = pdf.internal.pageSize.getWidth(); // Ancho de la página PDF
    const textWidth = 29; // Ancho del texto que quieres agregar

    // Calcular la posición X del texto
    const textX = pdfWidth - textWidth - 1;

    // Calcular la posición Y inicial del texto
    const textY = 5;
    const lineHeight = 3; // Espacio entre cada línea de texto

    // Calcular el máximo de caracteres a mostrar en el nombre
    const maxNameLength = 20; // Longitud máxima del nombre
    let truncatedName = equipment.name.substring(0, maxNameLength); // Obtener los primeros 15 caracteres

    // Agregar "..." si el nombre supera los 15 caracteres
    if (equipment.name.length > maxNameLength) {
      truncatedName += "...";
    }

    pdf.text(`Equipo: `, textX, textY);

    pdf.setFont("helvetica", "bold");

    // Agregar el valor del proyecto debajo del texto "Nombre:"
    pdf.text(`${truncatedName}`, textX, textY + lineHeight);

    pdf.setFont("helvetica", "normal");

    // Calcular el máximo de caracteres a mostrar en la descripción
    const maxDescriptionLength = 20;
    let truncatedDescription = equipment.description.substring(
      0,
      maxDescriptionLength
    );

    if (equipment.description.length > maxDescriptionLength) {
      truncatedDescription += "...";
    }

    // Agregar el texto "Descripción:" en la siguiente línea
    pdf.text(`Descripción:`, textX, textY + 2 * lineHeight);

    pdf.setFont("helvetica", "bold");

    // Agregar la descripción truncada al PDF
    pdf.text(`${truncatedDescription}`, textX, textY + 3 * lineHeight);

    pdf.setFont("helvetica", "normal");

    const maxSucursalLength = 20;
    let truncatedSucursal = equipment.Project.BranchOffice.name.substring(
      0,
      maxSucursalLength
    );

    if (equipment.Project.BranchOffice.name.length > maxSucursalLength) {
      truncatedSucursal += "...";
    }

    // Agregar el texto "Sucursal:" seguido del nombre de la sucursal del proyecto
    pdf.text(`Sucursal:`, textX, textY + 4 * lineHeight);

    pdf.setFont("helvetica", "bold");

    // Agregar el nombre de la sucursal en la siguiente línea
    pdf.text(`${truncatedSucursal}`, textX, textY + 5 * lineHeight);

    pdf.setFont("helvetica", "normal");

    if (
      equipment.area !== null &&
      equipment.area !== "" &&
      equipment.area !== 0 &&
      equipment.area !== undefined
    ) {
      /*  const maxAreaLength = 20;
      let truncatedArea = equipment.area.substring(0, maxAreaLength);

      if (equipment.area.length > maxAreaLength) {
        truncatedArea += "...";
      } */

      pdf.text(`Área:`, textX, textY + 6 * lineHeight);

      pdf.setFont("helvetica", "bold");

      pdf.text(`${equipment.area}`, textX, textY + 7 * lineHeight);
    } else {
      pdf.setFont("helvetica", "normal");

      const maxEmpresaLength = 15;
      let truncatedEmpresa =
        equipment.Project.BranchOffice.Company.name.substring(
          0,
          maxEmpresaLength
        );

      if (
        equipment.Project.BranchOffice.Company.name.length > maxEmpresaLength
      ) {
        truncatedEmpresa += "...";
      }

      pdf.text(`Empresa:`, textX, textY + 6 * lineHeight);

      pdf.setFont("helvetica", "bold");

      pdf.text(`${truncatedEmpresa}`, textX, textY + 7 * lineHeight);

      pdf.setFont("helvetica", "normal");
    }

    if (equipment.Project.BranchOffice.Company.name === "ACETI OXIGENO") {
      const logoWidth1 = 23;
      const logoHeight1 = 13;
      const logoX1 = 31;
      const logoY1 = 29;
      pdf.addImage(acetiLogo, "JPEG", logoX1, logoY1, logoWidth1, logoHeight1);
    } else if (equipment.Project.BranchOffice.Company.name === "PPC") {
      const logoWidth1 = 23;
      const logoHeight1 = 13;
      const logoX1 = 31;
      const logoY1 = 29;
      pdf.addImage(ppcLogo, "JPEG", logoX1, logoY1, logoWidth1, logoHeight1);
    } else if (equipment.Project.BranchOffice.Company.name === "SIESA") {
      const logoWidth1 = 23;
      const logoHeight1 = 13;
      const logoX1 = 31;
      const logoY1 = 29;
      pdf.addImage(siesaLogo, "JPEG", logoX1, logoY1, logoWidth1, logoHeight1);
    } else if (equipment.Project.BranchOffice.Company.name === "Productos del Aire") {
      const logoWidth1 = 23;
      const logoHeight1 = 13;
      const logoX1 = 31;
      const logoY1 = 29;
      pdf.addImage(siesaLogo, "JPEG", logoX1, logoY1, logoWidth1, logoHeight1);
    }

    /*  const logoWidth1 = 23;
    const logoHeight1 = 13;
    const logoX1 = 31;
    const logoY1 = 29;
    pdf.addImage(logoEmpresa, "JPEG", logoX1, logoY1, logoWidth1, logoHeight1); */

    // Agregar la segunda imagen al PDF
    const logoWidth2 = 26;
    const logoHeight2 = 15;
    const logoX2 = 2;
    const logoY2 = 29;
    pdf.addImage(siesaLogo, "JPEG", logoX2, logoY2, logoWidth2, logoHeight2);

    pdf.save(`${equipment.name}_equipment_stickerQR.pdf`);
  };

  const handleViewBlueprint = (id, name) => {
    if (!id || !name) {
      console.error("No se puede ver el id de equipo o name no esta definido.");
      return;
    }
    const type = "equipment";
    const url = `/data/blueprints/${encodeURIComponent(
      type
    )}/${id}/${encodeURIComponent(name)}`;

    navigate(url);
  };

  useEffect(() => {
    const fetchDataBranch = async () => {
      try {
        const response = await axios.get(
          `${API_ENDPOINT_USER}/user-details/${userId}`
        );

        const branch = response.data.BranchOfficeId;
        setUserBranch(branch);
      } catch (error) {
        console.error("Error al obtener datos de usuario y su sucursal", error);
      }
    };

    fetchDataBranch();
  }, [userId, userBranch]);

  useEffect(() => {
    const fetchDataCompany = async () => {
      if (userBranch !== undefined) {
        try {
          const response = await axios.get(
            `${API_ENDPOINT_BRANCH}/branch-office-details/${userBranch}`
          );

          const company = response.data.CompanyId;
          setUserCompany(company);
        } catch (error) {
          console.error(
            "Error al obtener datos de la sucursal y su empresa",
            error
          );
        }
      }
    };

    fetchDataCompany();
  }, [userBranch, userCompany]);

  return (
    <ChakraProvider>
      <Box
        style={{
          maxHeight: "100%",
          overflowY: "scroll",
          padding: "1rem",
        }}
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems="center"
          pb={4}
          justify="space-between"
        >
          {showSelectEquipment && (
            <>
              <Select
                placeholder={content[language].select}
                maxW={{ base: "100%", md: "250px" }}
                mb={{ base: 2, md: 0 }}
                mr={{ base: 0, md: 2 }}
                value={selectedBranch}
                onChange={(e) => setSelectedBranch(e.target.value)}
              >
                {branchs.map((branch) => (
                  <option key={branch.id} value={branch.name}>
                    {branch.name}
                  </option>
                ))}
              </Select>

              <Select
                placeholder={content[language].selectArea}
                maxW={{ base: "100%", md: "250px" }}
                mb={{ base: 2, md: 0 }}
                mr={{ base: 0, md: 2 }}
                value={selectedArea}
                onChange={(e) => setSelectedArea(e.target.value)}
              >
                <option value="0">{content[language].noArea}</option>
                {[
                  ...new Set(equipments.map((equipment) => equipment.area)),
                ].map((area) => (
                  <option key={area} value={area}>
                    {area}
                  </option>
                ))}
              </Select>
            </>
          )}
          <FormControl flex="1" mb={{ base: 2, md: 0 }} mr={{ base: 0, md: 2 }}>
            <InputGroup>
              <Input
                type="text"
                name="name"
                placeholder={content[language].searchEquipment}
                value={searchEquipment}
                onChange={(e) => setSearchEquipment(e.target.value)}
                maxW={{ base: "100%", md: "300px" }}
              />
            </InputGroup>
          </FormControl>
          {showButtonEquipment && (
            <Button
              bg={"gray.200"}
              color="black"
              _hover={{
                bg: "gray.300",
              }}
              borderColor="black"
              borderWidth="2px"
              leftIcon={<BiPlus />}
              mt={{ base: 2, md: 0 }}
              w={{ base: "95%", md: "auto" }}
              onClick={handleIconClick}
            >
              {content[language].addEquipment}
            </Button>
          )}
        </Flex>

        <TableContainer
          style={{
            boxShadow:
              "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>{content[language].number}</Th>
                <Th>{content[language].nameEquipment}</Th>
                <Th>{content[language].area}</Th>
                <Th>{content[language].nameProject}</Th>
                <Th>{content[language].nameBranch}</Th>
                <Th>{content[language].nameCompany}</Th>
                <Th>{content[language].action}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {equipments
                .filter((equipment) => {
                  const name = equipment.name.toLowerCase();
                  const searchByEquipment = name.includes(
                    searchEquipment.toLowerCase()
                  );
                  if (selectedBranch === "") {
                    return searchByEquipment;
                  } else {
                    const searchByBranch =
                      equipment.Project.BranchOffice.name === selectedBranch;
                    return searchByEquipment && searchByBranch;
                  }
                })
                .filter((equipment) => {
                  if (selectedArea === "0") {
                    return (
                      equipment.area === "" ||
                      equipment.area === null ||
                      equipment.area === undefined
                    );
                  } else if (selectedArea !== "") {
                    return equipment.area === selectedArea;
                  }
                  return true;
                })
                .filter((equipment) => {
                  if (parseInt(userRole) === 2 || parseInt(userRole) === 3) {
                    // "General Manager" y "Operations Manager"
                    return (
                      equipment.Project.BranchOffice.Company.id === userCompany
                    );
                  } else if (
                    parseInt(userRole) === 4 ||
                    parseInt(userRole) === 5
                  ) {
                    // "General Manager(branch)" y "Operations Manager(branch)"
                    return equipment.Project.BranchOffice.id === userBranch;
                  } else {
                    return true;
                  }
                })
                .map((equipment, index) => (
                  <React.Fragment key={equipment.id}>
                    <Tr>
                      <Th>{index + 1}</Th>
                      <Td>{equipment.name}</Td>
                      <Td>{equipment.area}</Td>
                      <Td>{equipment.Project.name}</Td>
                      <Td>{equipment.Project.BranchOffice.name}</Td>
                      <Td>{equipment.Project.BranchOffice.Company.name}</Td>
                      <Td>
                        {showButtonEquipment && (
                          <>
                            <Tooltip label={content[language].trash}>
                              <Button
                                colorScheme="red"
                                size="sm"
                                mr={2}
                                onClick={() =>
                                  handleDeleteEquipment(
                                    equipment.id,
                                    equipment.name
                                  )
                                }
                              >
                                <BiTrash />
                              </Button>
                            </Tooltip>
                            <Tooltip label={content[language].edit}>
                              <Button
                                colorScheme="blue"
                                size="sm"
                                mr={2}
                                onClick={() =>
                                  handleEditEquipment(equipment.id)
                                }
                              >
                                <BiPencil />
                              </Button>
                            </Tooltip>
                          </>
                        )}
                        <Tooltip label={content[language].more}>
                          <Button
                            onClick={() => toggleAccordion(equipment.id)}
                            colorScheme="gray"
                            size="sm"
                            mr={2}
                          >
                            {activeAccordion === equipment.id ? (
                              <BiChevronUp />
                            ) : (
                              <BiChevronDown />
                            )}
                          </Button>
                        </Tooltip>
                      </Td>
                    </Tr>
                    {activeAccordion === equipment.id && (
                      <Tr>
                        <Td colSpan={2}>
                          <HStack align="start" spacing={4} p={4}>
                            <Box>
                              <QRCode
                                value={JSON.stringify({
                                  id: equipment.id,
                                  nameOfEquipment: equipment.name,
                                  description: equipment.description,
                                  blueprint: equipment.blueprint,
                                  nameOfProject: equipment.Project.name,
                                  branchOffice:
                                    equipment.Project.BranchOffice.name,
                                  company:
                                    equipment.Project.BranchOffice.Company.name,
                                })}
                                size={100}
                              />
                            </Box>

                            <VStack align="start" spacing={4}>
                              <Text fontWeight="bold">
                                {equipment.name + " | "}
                                {equipment.description}
                              </Text>

                              {/* Botones */}
                              <HStack>
                                <Flex
                                  direction={{ base: "column", md: "row" }}
                                  align={{ base: "start", md: "center" }}
                                  spacing={2}
                                >
                                  {showButtonEquipment && (
                                    <>
                                      <Button
                                        size="sm"
                                        colorScheme="gray"
                                        leftIcon={<FaDownload />}
                                        onClick={() => generatePDF(equipment)}
                                        mr={{ base: 0, md: 2 }}
                                      >
                                        {content[language].download}
                                      </Button>
                                    </>
                                  )}
                                  <Button
                                    size="sm"
                                    colorScheme="gray"
                                    leftIcon={<FaEye />}
                                    mt={{ base: 2, md: 0 }}
                                    onClick={() =>
                                      handleViewBlueprint(
                                        equipment.id,
                                        equipment.name
                                      )
                                    }
                                  >
                                    {content[language].view}
                                  </Button>
                                </Flex>
                              </HStack>
                            </VStack>

                            <Spacer />
                          </HStack>
                        </Td>
                      </Tr>
                    )}
                  </React.Fragment>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>

      {deleteEquipmentId && (
        <AlertDialog
          isOpen={isDeleteConfirmationModalOpen}
          leastDestructiveRef={cancelRef}
          onClose={handleCancelDelete}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                {content[language].confirmDeleteTitle}{" "}
                <strong>{deleteEquipmentName}</strong>
              </AlertDialogHeader>

              <AlertDialogBody>
                {content[language].confirmDeleteMessage}
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={handleCancelDelete}>
                  {content[language].cancel}
                </Button>
                <Button colorScheme="red" onClick={handleConfirmDelete} ml={3}>
                  {content[language].delete}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}

      {editEquipmentId && (
        <ModalEditEquipment
          isOpen={isEditModalOpen}
          onClose={() => {
            setEditEquipmentId(null);
            setIsEditModalOpen(false);
            fetchData();
          }}
          equipmentId={editEquipmentId}
          fetchData={fetchData}
        />
      )}

      <ModalAddEquipment
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        fetchData={fetchData}
      />
    </ChakraProvider>
  );
};

export default EquipmentDashboard;
